import Auth from '@aws-amplify/auth';
import PubSub from 'pubsub-js';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Topics } from '../../broadcast/Types';
import logger from '../../util/Logger';
//import ApiManager from '../../api/ApiManager';
import AppInstanceId from '../AppInstanceId';
import Keychain from '../../util/Keychain';
import Utils from '../../util/Utils';
import PersistentApiManager from '../../api/PersistentApiManager';

const DEFAULT_DOMAIN = 'com.iotree.cds';
const LOG_TAG = 'AuthManager';
//const IOTREE_VALUE = 'HLtU7Kqw9627idEQU8p0JbIKaZmRf1D89WbHtGre';

export const CREDS_STATUS = Object.freeze({
  INVALID_STATE: -1,
  VALID_TOKEN: 1,
  NO_TOKEN: 2,
  EXPIRED_TOKEN: 3,
});

export const CREDS_PROVIDER = Object.freeze({
  COGNITO_NATIVE: 1,
  FEDERATED_GOOGLE: 2,
});

let state = {
  username: null,
  token: null,
  tokenData: null,
  userData: null,
  provider: CREDS_PROVIDER.COGNITO_NATIVE,
  loaded: false,
  refreshPromise: null,
};

class AuthManager {
  /**
   * Ensure you only call this after state has been loaded
   */
  isFederatedProvider(): boolean {
    if (!state.loaded) {
      logger.warn(LOG_TAG, 'provider type called when not loaded');
      return false;
    }
    return state.provider !== CREDS_PROVIDER.COGNITO_NATIVE;
  }

  mapProviderToName(provider: number): string {
    switch (provider) {
      case CREDS_PROVIDER.FEDERATED_GOOGLE:
        return 'Google';
      default:
        return null;
    }
  }

  async storeData(data: string) {
    await Keychain.saveData(DEFAULT_DOMAIN, data);
  }

  async retrieveData() {
    return await Keychain.getData(DEFAULT_DOMAIN);
  }

  async haveCreds() {
    if (state.loaded && state.username &&
      state.token && state.tokenData && state.userData) {
      return true;
    } else if (!state.loaded) {
      await this.loadCreds();
      return (state.loaded && state.username &&
        state.token &&
        state.tokenData && state.userData);
    }
  }

  getCredsStatus() {
    if (!state.loaded) {
      return CREDS_STATUS.INVALID_STATE;
    }
    if (!state.token || !state.tokenData) {
      return CREDS_STATUS.NO_TOKEN;
    }
    if (state.tokenData.exp > (Date.now() / 1000)) {
      return CREDS_STATUS.VALID_TOKEN;
    }
    return CREDS_STATUS.EXPIRED_TOKEN;
  }

  async saveCreds(username: string, token: string, provider: number,
    tokenData, userData) {
    if (!username || !token || !tokenData || !userData) {
      throw new Error('INVALID_ARGS');
    }
    const data = {
      provider: provider,
      token: token,
      tokenData: tokenData,
      userData: userData,
      username: username,
    };
    await this.storeData(JSON.stringify(data));
    state.username = username;
    state.provider = provider;
    state.token = token;
    state.tokenData = tokenData;
    state.userData = userData;
    state.loaded = true;
    this.broadcast({
      state: 'credsRefreshed',
    });
  }

  async getCreds() {
    if (!state.loaded) {
      await this.loadCreds();
    }
    return Object.freeze({
      username: state.username,
      provider: state.provider,
      token: state.token,
      tokenData: state.tokenData,
    });
  }

  async getUserData() {
    if (!state.loaded) {
      await this.loadCreds();
    }
    return Object.freeze({
      data: state.userData,
    });
  }

  async loadCreds() {
    try {
      const data = await this.retrieveData();
      const credentials = Utils.safeJsonParse(data);
      if (credentials) {
        logger.debug(LOG_TAG, 'Loaded credentials for ' +
          credentials.username);
        state.username = credentials.username;
        state.provider = credentials.provider ? credentials.provider :
          CREDS_PROVIDER.COGNITO_NATIVE;
        state.token = credentials.token;
        state.tokenData = credentials.tokenData;
        state.userData = credentials.userData;
        // logger.debug(LOG_TAG, 'token is', credentials.token);
      } else {
        logger.debug(LOG_TAG, 'No credentials stored');
        state.username = null;
        state.provider = CREDS_PROVIDER.COGNITO_NATIVE;
        state.token = null;
        state.tokenData = null;
        state.userData = null;
      }
      state.loaded = true;
    } catch (error) {
      logger.error(LOG_TAG, 'Keychain couldnt be accessed!', error);
    }
  }

  async signOut() {
    if (state.provider === CREDS_PROVIDER.FEDERATED_GOOGLE) {
      try {
        //await GoogleSignin.signOut();
        // this is taken from the react-google-login lib
        // see https://github.com/anthonyjgrove/react-google-login/
        // at src/use-google-logout.js: 24
        const gapi = (window as any).gapi;
        if (gapi) {
          const auth2 = gapi.auth2.getAuthInstance();
          if (auth2 != null) {
            await auth2.signOut();
            //auth2.disconnect();
          }
        }
      } catch (err) {
        logger.error(LOG_TAG, 'FedSgnout:failed:', err);
      }
    }
    await Auth.signOut();
    await this.clearCreds();
  }

  async clearCreds() {
    state.username = null;
    state.provider = CREDS_PROVIDER.COGNITO_NATIVE;
    state.token = null;
    state.tokenData = null;
    state.userData = null;
    PersistentApiManager.disconnect('signedOut');
    await Keychain.deleteData();
    await AppInstanceId.clearInstanceId();
    this.broadcast({
      state: 'signedOut',
    });
    return true;
  }

  /**
   * All params should be validated before calling this method
   * @param name - display name for user
   * @param email - email for the user
   * @param password - password for the user
   */
  async nativeSignUp(name: string, email: string, password: string) {
    try {
      const signupInfo = {
        username: email,
        password: password,
        attributes: {
          name: name,
          email: email,
        },
      };
      const data = await Auth.signUp(signupInfo);
      return {
        status: true,
        data: data,
      };
    } catch (error) {
      logger.error(LOG_TAG, 'failed to do native signup:', error);
      return {
        status: false,
        error: error,
      };
    }
  }

  async nativeConfirmSignup(username: string, code: string) {
    try {
      await Auth.confirmSignUp(username, code);
      return true;
    } catch (error) {
      logger.error(LOG_TAG, 'NvCnfSup:failed:', error);
    }
    return false;
  }

  async nativeSignin(email: string, password: string, otp?: string) {
    let authRes;
    let authDone = false;
    try {
      authRes = await Auth.signIn(email, password);
      if (!authRes.challengeName) {
        authDone = true;
      } else if (process.env.REACT_APP_ADMIN_PORTAL === '1') {
        if (authRes.challengeName === 'SOFTWARE_TOKEN_MFA') {
          if (otp && otp.length > 0) {
            authRes = await Auth.confirmSignIn(authRes, otp,
                'SOFTWARE_TOKEN_MFA');
            // success for this API does not remove challengeName
            // and challengeParams like signIn does - so accept
            // success as long as no error is thrown
            authDone = true;
          }
        }
      }
    } catch (error) {
      logger.error(LOG_TAG, 'NvSgnin:failed:', error);
    }
    if (authDone) {
      // sign in is a success, now check that email is verified
      const data = await Auth.verifiedContact(authRes);
      if (data.unverified && data.unverified['email']) {
        return {
          status: false,
          code: 'VERIFY_EMAIL',
          attr: data.unverified['email'],
        };
      } else {
        // everything is fine, retrieve tokens and store them
        const session = await Auth.currentSession();
        await this.saveSession(session,
          CREDS_PROVIDER.COGNITO_NATIVE);
        return {
          status: true,
        };
      }
    }
    return {
      status: false,
      code: 'ERROR',
    };
  }

  async nativeStartVerifyEmail() {
    try {
      await Auth.verifyCurrentUserAttribute('email');
      return true;
    } catch (error) {
      logger.error(LOG_TAG, 'NvStVerEmail:failed:', error);
    }
    return false;
  }

  async nativeCompleteVerifyEmail(code: string) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        'email', code);
      // store the credentials now as verification is complete
      const session = await Auth.currentSession();
      await this.saveSession(session,
        CREDS_PROVIDER.COGNITO_NATIVE);
      return true;
    } catch (error) {
      logger.error(LOG_TAG, 'NvCmpVerEmail:failed:', error);
    }
    return false;
  }

  async nativeResendSignupCode(username: string) {
    try {
      await Auth.resendSignUp(username);
    } catch (error) {
      logger.error(LOG_TAG, 'NvRsSupCode:failed:', error);
    }
    return false;
  }

  async nativeForgotPassword(email: string) {
    try {
      const data = await Auth.forgotPassword(email);
      return {
        status: true,
        delivery: data.CodeDeliveryDetails,
      };
    } catch (error) {
      logger.error(LOG_TAG, 'NvFgPwd:failed:', error);
    }
    return {
      status: false,
    };
  }

  async nativeChangePassword(email: string, code: string,
    newPassword: string) {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      return true;
    } catch (error) {
      logger.error(LOG_TAG, 'NvChPwd:failed:', error);
    }
    return false;
  }

  /*
  async federatedSignUp(provider: number, idToken: string) {
    try {
      const res = await ApiManager.rawPost('/users', {
        provider: this.mapProviderToName(provider),
        token: idToken,
      }, {}, {
        'x-iotree-api-key': IOTREE_VALUE,
      });
      return {
        status: true,
        data: res,
      };
    } catch (error) {
      if (error.response && error.response.status === 304) {
        return {
          status: true,
          data: error.response,
        };
      }
      logger.error(LOG_TAG, 'failed to do federated signup:', error);
      return {
        status: false,
        error: error,
      };
    }
  }
  */

  async federatedSignIn(provider: number, email: string,
    idToken: string) {
    try {
      const signInRes = await Auth.signIn(
        {
          username: email,
          password: null,
          validationData: {
            token: idToken,
          },
        },
      );
      if (!signInRes) {
        logger.error(LOG_TAG, 'FedSignIn failed:null response');
        return {
          status: false,
          error: new Error('Auth null response'),
        };
      }
      if (signInRes.challengeName === 'CUSTOM_CHALLENGE') {
        const cognitoUser = await Auth.sendCustomChallengeAnswer(
          signInRes,
          idToken,
        );
        if (!cognitoUser.challengeName) {
          const session = await Auth.currentSession();
          await this.saveSession(session,
            CREDS_PROVIDER.FEDERATED_GOOGLE);
          return {
            status: true,
            data: session,
          };
        } else {
          logger.error(LOG_TAG, 'FedSignIn: custom answer failed');
          return {
            status: false,
            error: new Error('Auth challenge failed'),
          };
        }
      } else {
        // this means we are logged in as a valid user, get the session
        const session = await Auth.currentSession();
        await this.saveSession(session,
          CREDS_PROVIDER.FEDERATED_GOOGLE);
        return {
          status: true,
          data: session,
        };
      }
    } catch (error) {
      logger.error(LOG_TAG, 'FedSignIn:failed with err:', error);
      return {
        status: false,
        error: error,
      };
    }
  }

  async saveSession(session: CognitoUserSession, provider: number) {
    const username = session.getIdToken().payload.sub;
    const token = session.getIdToken().getJwtToken();
    const tokenData = session.getIdToken().payload;
    const userData = session.getIdToken().payload;
    await this.saveCreds(username, token, provider,
      tokenData, userData);
  }

  async refreshFederatedToken(provider: number) {
    if (provider !== CREDS_PROVIDER.FEDERATED_GOOGLE) {
      throw new Error('unsupported federated provider:' + provider);
    }
    try {
      //const userInfo = await GoogleSignin.signInSilently();
      let userInfo = null;
      const gapi = (window as any).gapi;
      if (gapi) {
        const auth2 = gapi.auth2.getAuthInstance();
        if (auth2 != null) {
          if (auth2.isSignedIn.get()) {
            const currUser = auth2.currentUser.get();
            userInfo = currUser.getAuthResponse(true);
            // TODO: need to check if we need to do refresh here
          }
        }
      }
      return userInfo !== null;
    } catch (error) {
      throw error;
    }
  }

  async refreshNativeCreds() {
    let session;
    try {
      session = await Auth.currentSession();
    } catch (error) {
      if (error.code &&
        error.code === 'NotAuthorizedException') {
        try {
          await this.clearCreds();
        } catch (err) {
          logger.error(LOG_TAG, 'rshNatCreds:failed to clear creds:',
            err);
        }
      }
      throw error;
    }
    await this.saveSession(session, CREDS_PROVIDER.COGNITO_NATIVE);
  }

  async refreshCreds() {
    if (state.refreshPromise !== null) {
      await state.refreshPromise;
      return;
    }
    const that = this;
    state.refreshPromise = new Promise<void>((resolve, reject) => {
      // if this is a federated provider, revalidate that token
      // first
      if (that.isFederatedProvider()) {
        that.refreshFederatedToken(state.provider)
          .then((valid) => {
            if (!valid) {
              state.refreshPromise = null;
              reject(new Error('failed to refresh provider token'));
              return;
            }
            // now that provider is valid, refresh the native creds
            that.refreshNativeCreds()
              .then(() => {
                state.refreshPromise = null;
                resolve();
              })
              .catch((err) => {
                state.refreshPromise = null;
                reject(err);
              });
          })
          .catch((err) => {
            state.refreshPromise = null;
            reject(err);
          });
      } else {
        that.refreshNativeCreds()
          .then(() => {
            state.refreshPromise = null;
            resolve();
          })
          .catch((err) => {
            state.refreshPromise = null;
            reject(err);
          });
      }
    });
    await state.refreshPromise;
  }

  broadcast(data) {
    PubSub.publish(Topics.AUTH_STATE, data);
  }
}

const authManager = new AuthManager();
export default authManager;
