import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import awsconfig from './app/state/auth/aws-config';
import { ProvideAuth, PrivateRoute } from './app/ui/UiAuth';
import './scss/style.scss';

// init amplify
Auth.configure(awsconfig.amplifyParams.Auth);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const OAuth = React.lazy(() => import('./views/pages/login/OAuth'));
//const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <ProvideAuth>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/oauth/google" name="OAuth" render={props => <OAuth {...props}/>} />
              {/*
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              */}
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <PrivateRoute path="/" name="Home">
                <TheLayout />
              </PrivateRoute>
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </ProvideAuth>
    );
  }
}

export default App;
