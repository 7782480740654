import ApiManager from '../api/ApiManager';
import Logger from '../util/Logger';
import {
  MotionArea, ScheduleInterval, ObjectDetectionEnv,
  DistanceBoundaryPoint, DistanceSeparationPoint
} from './DeviceConstants';

const LOG_TAG = 'DeviceManager';

class DeviceManager {
  async getDeviceState(deviceId: string) {
    try {
      const response = await ApiManager.get('/devices/' + deviceId + '/state');
      if (response.status !== 200) {
        return { status: false, data: null };
      } else {
        return { status: true, data: response.data };
      }
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to get device state', error);
      return { status: false, data: null };
    }
  }

  // gets both state and settings in single api call
  async getDeviceData(deviceId: string) {
    try {
      const response = await ApiManager.get('/devices/' + deviceId + '/data');
      if (response.status !== 200) {
        return { status: false, data: null };
      } else {
        return { status: true, data: response.data };
      }
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to get device data', error);
      return { status: false, data: null };
    }
  }

  async getDeviceConfig(deviceId: string) {
    try {
      const response = await ApiManager.get('/devices/' + deviceId + '/config');
      if (response.status !== 200) {
        return { status: false, data: null };
      } else {
        return { status: true, data: response.data };
      }
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to get device config', error);
      return { status: false, data: null };
    }
  }

  async getDeviceSettings(deviceId: string) {
    try {
      const response = await ApiManager.get('/devices/' + deviceId +
        '/settings');
      if (response.status !== 200) {
        return { status: false, data: null };
      } else {
        return { status: true, data: response.data };
      }
    } catch (err) {
      Logger.error(LOG_TAG, 'failed to read device settings', err);
      return { status: false, data: null };
    }
  }

  async runDeviceCommand(deviceId: string, model: any) {
    try {
      const response = await ApiManager.post('/devices/' + deviceId +
        '/command', model);
      if (response.status !== 200) {
        return { status: false, data: null };
      } else {
        return { status: true, data: response.data };
      }
    } catch (error) {
      throw error;
    }
  }

  async setDeviceMode(deviceId: string, mode: number,
    ruleId: string = null) {
    try {
      const model = {
        id: 'mode.set',
        params: {
          mode: mode,
          ruleId: ruleId ? ruleId : undefined,
        },
      };
      const response = await this.runDeviceCommand(deviceId, model);
      return response;
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to set device mode', error);
      return { status: false, data: null };
    }
  }

  async triggerBugreport(deviceId: string) {
    try {
      const model = {
        id: 'debug.bugreport',
      };
      const response = await this.runDeviceCommand(deviceId, model);
      return response;
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to trigger bugreport', error);
      return { status: false, data: null };
    }
  }

  async updateDeviceSoftware(deviceId: string) {
    try {
      const model = {
        id: 'sw.update',
      };
      const response = await this.runDeviceCommand(deviceId, model);
      return response;
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to trigger sw update', error);
      return { status: false, data: null };
    }
  }

  async setMotionSettings(deviceId: string, enabled: boolean,
    sensitivity: number, motionAreas: Array<MotionArea> = [],
    ignoreAreas: Array<MotionArea> = []) {
    try {
      const model = {
        id: 'settings.motion.set',
        params: {
          enabled: enabled,
          sensitivity: sensitivity,
          motionAreas: motionAreas,
          ignoreAreas: ignoreAreas,
        },
      };
      const response = await this.runDeviceCommand(deviceId, model);
      return response;
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to set motion settings', error);
      return { status: false, data: null };
    }
  }

  async setObjectSettings(deviceId: string, enabled: boolean,
    env: ObjectDetectionEnv, boundary: DistanceBoundaryPoint[],
    separation: DistanceSeparationPoint[], useMotionZones: boolean,
    onlyMovingObjects: boolean) {
    try {
      const model = {
        id: 'settings.object.set',
        params: {
          enabled: enabled,
          env: env,
          boundary: boundary ? boundary : undefined,
          separation: separation ? separation : undefined,
          useMotionZones: typeof useMotionZones === 'boolean' ?
            useMotionZones : false,
          onlyMovingObjects: typeof onlyMovingObjects === 'boolean' ?
            onlyMovingObjects : false,
        },
      };
      const response = await this.runDeviceCommand(deviceId, model);
      return response;
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to set object settings', error);
      return { status: false, data: null };
    }
  }

  async setSchedule(deviceId: string, type: number, timezone: string,
    intervals: Array<ScheduleInterval>) {
    try {
      const model = {
        id: 'settings.schedule.set',
        params: {
          type: type,
          tz: timezone,
          intervals: intervals,
        },
      };
      const response = await this.runDeviceCommand(deviceId, model);
      return response;
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to set schedule', error);
      return { status: false, data: null };
    }
  }

  async setRule(deviceId: string, ruleId: string) {
    try {
      const model = {
        id: 'settings.rule.set',
        params: {
          ruleId: ruleId,
        },
      };
      const response = await this.runDeviceCommand(deviceId, model);
      return response;
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to set rule', error);
      return { status: false, data: null };
    }
  }
}

const deviceManager = new DeviceManager();
export default deviceManager;
