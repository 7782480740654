const LOG_LEVELS = Object.freeze({
  DISABLED: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
  VERBOSE: 5,
});

const LOG_LEVEL = LOG_LEVELS.DEBUG;

class Logger {
  verbose(tag: string, log: string, error = null) {
    if (LOG_LEVEL >= LOG_LEVELS.VERBOSE) {
      if (error) {
        console.log(tag, log, error);
      } else {
        console.log(tag, log);
      }
    }
  }

  debug(tag: string, log: string, error = null) {
    if (LOG_LEVEL >= LOG_LEVELS.DEBUG) {
      if (error) {
        console.log(tag, log, error);
      } else {
        console.log(tag, log);
      }
    }
  }

  info(tag: string, log: string, error = null) {
    if (LOG_LEVEL >= LOG_LEVELS.INFO) {
      if (error) {
        console.log(tag, log, error);
      } else {
        console.log(tag, log);
      }
    }
  }

  warn(tag: string, log: string, error = null) {
    if (LOG_LEVEL >= LOG_LEVELS.WARN) {
      if (error) {
        console.log(tag, log, error);
      } else {
        console.log(tag, log);
      }
    }
  }

  error(tag: string, log: string, error = null) {
    if (LOG_LEVEL >= LOG_LEVELS.ERROR) {
      if (error) {
        console.log(tag, log, error);
      } else {
        console.log(tag, log);
      }
    }
  }
}

const loggerInstance = new Logger();
export default loggerInstance;
