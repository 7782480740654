import ApiManager from '../api/ApiManager';
import logger from '../util/Logger';

const TAG = 'UserManager';
const TGID = process.env.REACT_APP_ADMIN_PORTAL === '1' ?
    'ea548b48-b65c-46d7-b8bb-f324809bf739' : '';

interface UserInterface {
  searchUser: (email: string) => Promise<string>,
  getTenantId: () => string,
}

class UserManager implements UserInterface {
  async searchUser(email: string) {
    let userGid = null;
    try {
      const res = await ApiManager.get('/users', {
        tenant_gid: TGID,
        email: email,
      });
      if (res.status === 200) {
        userGid = res.data.user_id;
      }
    } catch (err) {
      logger.debug(TAG, 'SrchUser:failed to search:' + email,
          err);
    }
    return userGid;
  }

  getTenantId() {
    return TGID;
  }
}

class UserStub implements UserInterface {
  async searchUser(email: string) {
    return null;
  }

  getTenantId() {
    return '';
  }
}

const instance = new UserManager();
export default process.env.REACT_APP_ADMIN_PORTAL === '1' ?
    instance : new UserStub();
