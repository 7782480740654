import logger from '../util/Logger';
import ApiManager from '../api/ApiManager';
import { SubscriptionResult } from './SubscriptionManager';
import { AccessLevel } from './PermissionsManager';
import Utils from '../util/Utils';

const DATA_REFRESH_INTERVAL = 5 * 60 * 1000; // in ms

export interface SiteChangedCallback {
  onSiteChanged: (data: any) => void,
}

export interface DeviceChangedCallback {
  onDevicesChanged: (siteId: string, deviceId: string, data: any) => void,
}

export interface Site {
  id: string,
  name: string,
  access_level: AccessLevel,
}

export interface Device {
  id: string,
  name: string,
  type: string,
  is_gateway: boolean,
  gateway_id: string,
  source_id: number,
  site_id: string,
  access_level: AccessLevel,
}

const LOG_TAG = 'DataManager';

interface InnerState {
  config: {
    siteLoadTime?: number,
    deviceLoadTimes?: {
      [id: string]: number,
    },
    regionLoadTimes?: {
      [id: string]: number,
    },
  },
  sites: Site[],
  regions: {
    [siteId: string]: {},
  },
  devices: {
    [siteId: string]: Device[],
  },
  subscriptions: {
    [siteId: string]: SubscriptionResult,
  },
  user: {
    id?: string,
  },
  currentSiteId: string,
  currentDeviceId: string,
  storeLoaded: boolean,
  siteCallbacks: Set<SiteChangedCallback>,
  regionCallbacks: Set<SiteChangedCallback>,
  deviceCallbacks: Set<DeviceChangedCallback>,
}

class DataManager {
  state: InnerState = {
    config: null,
    sites: null,
    regions: null,
    devices: null,
    subscriptions: null,
    user: null,
    currentSiteId: null,
    currentDeviceId: null,
    storeLoaded: false,
    siteCallbacks: new Set<SiteChangedCallback>(),
    regionCallbacks: new Set(),
    deviceCallbacks: new Set<DeviceChangedCallback>(),
  }

  async getSites(sync: boolean) {
    try {
      await this.loadStore();
      if (sync === true || ((Date.now() - this.state.config.siteLoadTime) >
        DATA_REFRESH_INTERVAL)) {
        await this.fetchSites();
      }
    } catch (error) {
      logger.error(LOG_TAG, 'failed to get sites', error);
      return [];
    }
    return this.state.storeLoaded ? this.state.sites : [];
  }

  peekSites() {
    if (this.state.storeLoaded) {
      const sites = this.state.sites;
      return sites ? sites : null;
    } else {
      return [];
    }
  }

  peekSite(siteId: string) {
    let site = null;
    const sites = this.peekSites();
    sites.forEach(s => {
      if (s.id === siteId) {
        site = s;
        return true;
      }
      return false;
    });
    return site;
  }

  sortEntities(entities: Array<any>, ownerFirst: boolean = true) {
    entities.sort((a, b) => {
      if (ownerFirst) {
        if (a.access_level == AccessLevel.ADMIN &&
          b.access_level == AccessLevel.ADMIN) {
          const cmp = a.name.localeCompare(b.name);
          return cmp < 0 ? -1 : (cmp > 0 ? 1 : 0);
        }
        if (a.access_level == AccessLevel.ADMIN) {
          return -1;
        } else if (b.access_level == AccessLevel.ADMIN) {
          return 1;
        }
      }
      // either dont consider owner or both sites not owned
      const cmp = a.name.localeCompare(b.name);
      return cmp < 0 ? -1 : (cmp > 0 ? 1 : 0);
    });
  }

  async fetchSites() {
    try {
      const response = await ApiManager.get('/sites');
      if (response.status === 200) {
        this.state.sites = response.data;
        this.sortEntities(this.state.sites);
        this.state.config.siteLoadTime = Date.now();
        this.notifySiteCallbacks();
      }
    } catch (error) {
      logger.error(LOG_TAG, 'failed to fetch sites', error);
      throw error;
    }
  }

  async createSite(siteModel: Site) {
    try {
      const response = await ApiManager.post('/sites', siteModel);
      logger.debug(LOG_TAG, `create site response is ${response}`);
      if (response.status === 201) {
        siteModel.id = response.data.id;
        this.state.sites.push(siteModel);
        this.sortEntities(this.state.sites);
        this.notifySiteCallbacks();
        return siteModel as Site;
      } else {
        return null;
      }
    } catch (error) {
      logger.error(LOG_TAG, 'failed to create site', error);
      return null;
    }
  }

  async updateSite(siteModel: Site) {
    try {
      const res = await ApiManager.put('/sites/' + siteModel.id,
        siteModel);
      if (res.status === 200) {
        for (let i = 0; i < this.state.sites.length; i++) {
          if (this.state.sites[i].id === siteModel.id) {
            this.state.sites[i] = siteModel;
            break;
          }
        }
        this.sortEntities(this.state.sites);
        this.notifySiteCallbacks();
        return siteModel;
      } else {
        logger.error(LOG_TAG, 'failed to update site:', res.status);
        return null;
      }
    } catch (error) {
      logger.error(LOG_TAG, 'failed to update site', error);
      return null;
    }
  }

  async deleteSite(siteModel: Site) {
    try {
      await ApiManager.delete('/sites/' + siteModel.id);
      for (let i = 0; i < this.state.sites.length; i++) {
        if (this.state.sites[i].id === siteModel.id) {
          this.state.sites.splice(i, 1);
          break;
        }
      }
      this.sortEntities(this.state.sites);
      this.notifySiteCallbacks();
      return true;
    } catch (error) {
      logger.error(LOG_TAG, 'failed to delete site', error);
      return false;
    }
  }

  addSiteCallback(callback: SiteChangedCallback) {
    this.state.siteCallbacks.add(callback);
  }

  removeSiteCallback(callback: SiteChangedCallback) {
    this.state.siteCallbacks.delete(callback);
  }

  notifySiteCallbacks(data = null) {
    this.state.siteCallbacks.forEach(cb => {
      cb.onSiteChanged(data);
    });
  }

  async getDevices(siteId: string, sync: boolean): Promise<Device[]> {
    try {
      await this.loadStore();
      if (sync === true ||
        !this.state.config.deviceLoadTimes[siteId] ||
        ((Date.now() - this.state.config.deviceLoadTimes[siteId]) >
          DATA_REFRESH_INTERVAL)) {
        await this.fetchDevices(siteId);
      }
    } catch (error) {
      logger.error(LOG_TAG, 'failed to get devices', error);
    }
    return this.state.storeLoaded ?
      Utils.duplicate(this.state.devices[siteId]) : [];
  }

  async peekDevices(siteId: string): Promise<Device[]> {
    if (this.state.storeLoaded) {
      if (!this.state.config.deviceLoadTimes[siteId]) {
        return await this.getDevices(siteId, false);
      }
      const devices = this.state.devices[siteId];
      return devices ? Utils.duplicate(devices) : [];
    } else {
      return await this.getDevices(siteId, false);
    }
  }

  peekDevice(deviceId: string) {
    let device: Device = null;
    const siteDevices = this.state.storeLoaded ?
      this.state.devices : {};
    Object.keys(siteDevices).some(key => {
      const devices = siteDevices[key];
      const found = devices.some(d => {
        if (d.id === deviceId) {
          device = d;
          return true;
        }
        return false;
      });
      return found;
    });
    return device;
  }

  async peekSiteForDevice(deviceId: string) {
    let siteId: string = null;
    await this.loadStore();
    const siteDevices = this.state.storeLoaded ?
      this.state.devices : {};
    Object.keys(siteDevices).some(key => {
      const devices = siteDevices[key];
      const found = devices.some(d => {
        if (d.id === deviceId) {
          siteId = key;
          return true;
        }
        return false;
      });
      return found;
    });
    return siteId;
  }

  async fetchSitesAndDevices() {
    await this.loadStore();
    try {
      const response = await ApiManager.get('/devices');
      if (response.status === 200) {
        const sites = [];
        const siteMap = {};
        const siteDevices = {};
        response.data.forEach(d => {
          if (!siteMap[d.site_id]) {
            siteMap[d.site_id] = d.site_name;
            sites.push({
              id: d.site_id,
              name: d.site_name,
            });
          }
          if (!siteDevices[d.site_id]) {
            siteDevices[d.site_id] = [];
          }
          siteDevices[d.site_id].push(d);
        });
        this.state.sites = sites;
        this.sortEntities(this.state.sites);
        this.state.config.siteLoadTime = Date.now();
        this.notifySiteCallbacks();
        this.state.devices = siteDevices;
        sites.forEach(s => {
          this.state.config.deviceLoadTimes[s.id] = Date.now();
          this.notifyDeviceCallbacks(s.id);
        });
      }
    } catch (error) {
      logger.error(LOG_TAG, 'failed to fetch site devices', error);
      throw error;
    }
  }

  async fetchDevices(siteId: string) {
    try {
      const response = await ApiManager.get('/devices', { site_id: siteId });
      if (response.status === 200) {
        // add in site_id to the model
        response.data.forEach(d => {
          d.site_id = siteId;
        });
        this.state.devices[siteId] = response.data;
        this.state.config.deviceLoadTimes[siteId] = Date.now();
        this.notifyDeviceCallbacks(siteId);
      }
    } catch (error) {
      logger.error(LOG_TAG, 'failed to fetch devices', error);
      throw error;
    }
  }

  async updateDevice(model: Device) {
    try {
      await ApiManager.put('/devices/' + model.id, model);
      const deviceList = this.state.devices[model.site_id];
      for (let i = 0; i < deviceList.length; i++) {
        if (deviceList[i].id === model.id) {
          deviceList[i] = model;
          break;
        }
      }
      this.notifyDeviceCallbacks(model.site_id, model.id);
      return model;
    } catch (error) {
      logger.error(LOG_TAG, 'failed to update device', error);
      return null;
    }
  }

  async deleteDevice(model: Device) {
    try {
      await ApiManager.delete('/devices/' + model.id);
      const siteId = model.site_id;
      for (let i = 0; i < this.state.devices[siteId].length; i++) {
        if (this.state.devices[siteId][i].id === model.id) {
          this.state.devices[siteId].splice(i, 1);
          break;
        }
      }
      this.notifyDeviceCallbacks(siteId);
      return true;
    } catch (error) {
      logger.error(LOG_TAG, 'failed to delete device', error);
      return false;
    }
  }

  addDeviceCallback(callback: DeviceChangedCallback) {
    this.state.deviceCallbacks.add(callback);
  }

  removeDeviceCallback(callback: DeviceChangedCallback) {
    this.state.deviceCallbacks.delete(callback);
  }

  notifyDeviceCallbacks(siteId: string, deviceId: string = null,
    data = null) {
    this.state.deviceCallbacks.forEach(cb => {
      cb.onDevicesChanged(siteId, deviceId, data);
    });
  }

  async getSubscriptions():
    Promise<{ [siteId: string]: SubscriptionResult }> {
    await this.loadStore();
    return this.state.storeLoaded ? this.state.subscriptions : {};
  }

  async storeSubscriptions(subs: { [siteId: string]: SubscriptionResult }) {
    this.state.subscriptions = subs;
  }

  async getUser() {
    await this.loadStore();
    if (!this.state.storeLoaded) {
      return null;
    }
    if (this.state.user.id) {
      return this.state.user;
    }
    try {
      const res = await ApiManager.get('/users/self');
      this.state.user = res.data;
      return this.state.user;
    } catch (err) {
      logger.debug(LOG_TAG, 'getUser:failed to get user:', err);
      return null;
    }
  }

  async clearState() {
    if (!this.state.storeLoaded) {
      return;
    }
    this.state.config = {
      siteLoadTime: 0,
      regionLoadTimes: {},
      deviceLoadTimes: {},
    };
    this.state.sites = [];
    this.state.regions = {};
    this.state.devices = {};
    this.state.subscriptions = {};
    this.state.user = {};
  }

  async loadStore() {
    if (this.state.storeLoaded) {
      return;
    }
    this.state.config = {
      siteLoadTime: 0,
      regionLoadTimes: {},
      deviceLoadTimes: {},
    };
    this.state.sites = [];
    this.state.regions = {};
    this.state.devices = {};
    this.state.subscriptions = {};
    this.state.user = {};
    this.state.storeLoaded = true;
  }

  getCurrentSiteId() {
    return this.state.currentSiteId;
  }

  getCurrentDeviceId() {
    return this.state.currentDeviceId;
  }

  setCurrentSiteId(siteId: string) {
    this.state.currentSiteId = siteId;
  }

  setCurrentDeviceId(deviceId: string) {
    this.state.currentDeviceId = deviceId;
  }
}

const dataManager = new DataManager();
export default dataManager;
