import ApiManager from '../api/ApiManager';
import logger from '../util/Logger';

export interface Sound {
  uid: string
  name: string
}

interface SoundList {
  siteId: string
  sounds: Sound[]
}

const TAG = 'SoundManager';

class SoundManager {
  async getSounds(siteId: string): Promise<SoundList> {
    try {
      const res = await ApiManager.get('/sounds', { site_id: siteId });
      if (res.status !== 200) {
        logger.error(TAG, 'GetS:api failed:', res.status);
        return null;
      }
      const list = res.data;
      return {
        siteId: siteId,
        sounds: list,
      };
    } catch (err) {
      logger.error(TAG, 'GetS:failed to get sounds:', err);
      return null;
    }
  }

  async deleteSound(soundId: string, siteId: string) {
    try {
      const response = await ApiManager.delete('/sounds/' + soundId, {
        site_id: siteId,
      });
      if (response.status !== 200) {
        return { status: false, data: null, code: response.status };
      } else {
        return { status: true, data: response.data };
      }
    } catch (error) {
      logger.error(TAG, 'DelS:failed to delete rule', error);
      let errCode = null, statusCode = null;
      if (error.response && error.response.data &&
        error.response.data.code) {
        errCode = error.response.data.code;
      }
      if (error.response && error.response.status) {
        statusCode = error.response.status;
      }
      return {
        status: false, data: null, code: statusCode,
        errCode: errCode,
      };
    }
  }
}

const soundManager = new SoundManager();
export default soundManager;
