import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import awsconfig from '../state/auth/aws-config';
import AuthManager from '../state/auth/AuthManager';
import logger from '../util/Logger';
import AppInstanceId from '../state/AppInstanceId';
import UiUserId from '../ui/UiUserData';
import {
  osVersion,
  osName,
  browserName,
  browserVersion,
  deviceType,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';

interface Context {
  isRetry?: boolean;
  shouldRetry?: boolean;
  numRetries?: number;
  maxRetries?: number;
  id?: string;
}

const API_ENDPOINT_NAME = 'ClientAPI';
const API_TIMEOUT = 60000; // in ms
const API_DEFAULT_RETRIES = 3;
const LOG_TAG = 'ApiManager';
const EXTRA_DEBUG = false;

const resolveEndpoint = () => {
  const endpoints = awsconfig.amplifyParams.API.endpoints;
  for (let i = 0; i < endpoints.length; i++) {
    if (endpoints[i].name === API_ENDPOINT_NAME) {
      return endpoints[i].endpoint + '/web/api';
    }
  }
  return null;
};

// device info
const bundleId = process.env.REACT_APP_ADMIN_PORTAL === '1' ?
    'iotree.admin.portal' : 'iotree.portal';
const buildNumber = '1.0.0';
const deviceModel = (mobileModel && mobileModel.length > 0) ? mobileModel : 'U';
const deviceVendor = (mobileVendor && mobileVendor.length > 0) ? mobileVendor : 'U';

const endpoint = resolveEndpoint();
const axiosInstance = axios.create({
  baseURL: endpoint,
  timeout: API_TIMEOUT,
  headers: {
    'x-iot-user-agent':
      bundleId + ' ' + buildNumber +
      ' (' + osName + ' ' + osVersion + ' ' + browserName +
      ' ' + browserVersion +
      ' ' + deviceModel + ' ' + deviceVendor +
      ' ' + deviceType +
      ')',
  },
});
if (EXTRA_DEBUG) {
  logger.debug(LOG_TAG, 'created axios instance', endpoint);
}

class ApiManager {
  preprocessRequest(context: Context) {
    if (typeof context.isRetry === 'undefined') {
      // first pass
      context.isRetry = false;
      if (typeof context.shouldRetry === 'undefined') {
        context.shouldRetry = true;
      }
      context.numRetries = 0;
      if (typeof context.maxRetries === 'undefined') {
        context.maxRetries = API_DEFAULT_RETRIES;
      }
      context.id = uuidv4();
    } else if (context.isRetry && context.shouldRetry) {
      // retry pass
      context.numRetries++;
      if (context.numRetries > context.maxRetries) {
        return false;
      }
    }
    return true;
  }

  async processError(error, context: Context) {
    // the error handling below follows the guide from
    // axios for error handling for the library
    if (error.response) {
      // The request was made and the server responded
      // with a status code that falls out of the range of 2xx
      logger.error(LOG_TAG, `status failure:${context.numRetries}`, error);
      if (error.response.status === 401) {
        try {
          await AuthManager.refreshCreds();
        } catch (authError) {
          logger.debug(LOG_TAG, 'refresh creds failed', authError);
          // if this is a refresh token failure, also sign out
          if (authError && authError.code === 'NotAuthorizedException') {
            try {
              await AuthManager.signOut();
            } catch (ignored) {}
          }
          // we cannot recover from this
          return authError;
        }
      } else {
        return error;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      // in the browser and an instance of http.ClientRequest
      // in node.js
      logger.error(LOG_TAG, `response failure:${context.numRetries}`, error);
    } else {
      // Something happened in setting up the request that
      // triggered an Error
      logger.error(
        LOG_TAG,
        `request setup failure:${context.numRetries}`,
        error,
      );
    }
    context.isRetry = true;
    return null;
  }

  async post(path: string, body, params = {}, headers = {}, context: Context = {}) {
    do {
      const canContinue = this.preprocessRequest(context);
      if (!canContinue) {
        throw new Error('MAX_RETRIES');
      }
      try {
        const appInstId = await AppInstanceId.getInstanceId();
        const creds = await AuthManager.getCreds();
        const token = creds.token;
        const response = await axiosInstance.post(path, body, {
          params: process.env.REACT_APP_ADMIN_PORTAL !== '1' ? params : {
            'user_gid': UiUserId.getUserGid(),
            ...params,
          },
          headers: {
            ...headers,
            'x-iot-req-id': context.id + '-' + appInstId,
            'Authorization': 'Bearer ' + token,
          },
        });
        if (EXTRA_DEBUG) {
          logger.debug(LOG_TAG, 'Post::response', response.data);
        }
        return response;
      } catch (error) {
        if (EXTRA_DEBUG) {
          logger.debug(LOG_TAG, 'Post::error', error);
        }
        const unrecoverableError = await this.processError(error, context);
        if (unrecoverableError === null) {
          continue;
        } else {
          throw unrecoverableError;
        }
      }
    } while (true);
  }

  async get(path: string, params = {}, headers = {}, context: Context = {}) {
    do {
      const canContinue = this.preprocessRequest(context);
      if (!canContinue) {
        throw new Error('MAX_RETRIES');
      }
      try {
        const appInstId = await AppInstanceId.getInstanceId();
        const creds = await AuthManager.getCreds();
        const token = creds.token;
        const response = await axiosInstance.get(path, {
          params: process.env.REACT_APP_ADMIN_PORTAL !== '1' ? params : {
            'user_gid': UiUserId.getUserGid(),
            ...params,
          },
          headers: {
            ...headers,
            'x-iot-req-id': context.id + '-' + appInstId,
            'Authorization': 'Bearer ' + token,
          },
        });
        if (EXTRA_DEBUG) {
          logger.debug(LOG_TAG, 'Get::got response', response.data);
        }
        return response;
      } catch (error) {
        logger.debug(LOG_TAG, 'Get::got error', error);
        const unrecoverableError = await this.processError(error, context);
        if (unrecoverableError === null) {
          continue;
        } else {
          throw unrecoverableError;
        }
      }
    } while (true);
  }

  async put(path: string, body, params = {}, headers = {}, context: Context = {}) {
    do {
      const canContinue = this.preprocessRequest(context);
      if (!canContinue) {
        throw new Error('MAX_RETRIES');
      }
      try {
        const appInstId = await AppInstanceId.getInstanceId();
        const creds = await AuthManager.getCreds();
        const token = creds.token;
        const response = await axiosInstance.put(path, body, {
          params: process.env.REACT_APP_ADMIN_PORTAL !== '1' ? params : {
            'user_gid': UiUserId.getUserGid(),
            ...params,
          },
          headers: {
            ...headers,
            'x-iot-req-id': context.id + '-' + appInstId,
            'Authorization': 'Bearer ' + token,
          },
        });
        logger.debug(LOG_TAG, 'PUT::success:', response.data);
        return response;
      } catch (error) {
        logger.error(LOG_TAG, 'PUT::error:', error);
        const unrecoverableError = await this.processError(error, context);
        if (unrecoverableError === null) {
          continue;
        } else {
          throw unrecoverableError;
        }
      }
    } while (true);
  }

  async delete(path: string, params = {}, headers = {}, context: Context = {}) {
    do {
      const canContinue = this.preprocessRequest(context);
      if (!canContinue) {
        throw new Error('MAX_RETRIES');
      }
      try {
        const appInstId = await AppInstanceId.getInstanceId();
        const creds = await AuthManager.getCreds();
        const token = creds.token;
        const response = await axiosInstance.delete(path, {
          params: process.env.REACT_APP_ADMIN_PORTAL !== '1' ? params : {
            'user_gid': UiUserId.getUserGid(),
            ...params,
          },
          headers: {
            ...headers,
            'x-iot-req-id': context.id + '-' + appInstId,
            'Authorization': 'Bearer ' + token,
          },
        });
        logger.debug(LOG_TAG, 'DELETE::success:', response.data);
        return response;
      } catch (error) {
        logger.error(LOG_TAG, 'DELETE::error:', error);
        const unrecoverableError = await this.processError(error, context);
        if (unrecoverableError === null) {
          continue;
        } else {
          throw unrecoverableError;
        }
      }
    } while (true);
  }

  async rawPost(path: string, body, params = {}, headers = {}) {
    try {
      const appInstId = await AppInstanceId.getInstanceId();
      const response = await axiosInstance.post(path, body, {
        params: params,
        headers: {
          ...headers,
          'x-iot-req-id': uuidv4() + '-' + appInstId,
        },
      });
      if (EXTRA_DEBUG) {
        logger.debug(LOG_TAG, 'RawPost::response', response.data);
      }
      return response;
    } catch (error) {
      if (EXTRA_DEBUG) {
        logger.debug(LOG_TAG, 'RawPost::error', error);
      }
      throw error;
    }
  }
}

const apiManager = new ApiManager();
export default apiManager;
