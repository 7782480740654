import { AuthStorage } from './AuthStorage';

const AwsConfig = {
  amplifyParams: {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'ap-south-1:81411c13-23fe-4663-b797-da2c5bc8e927',

      // REQUIRED - Amazon Cognito Region
      region: 'ap-south-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: 'ap-south-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_ADMIN_PORTAL === '1' ?
          'ap-south-1_sJiPRkz4D' : 'ap-south-1_24CIK30IH',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_ADMIN_PORTAL === '1' ?
          '3qq7n5cl84t2r3t3js28f2sghd' : '7o7835ku9611r9ijsa2mqdp3t1',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
      /*
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          domain: 'webapp-20190730142742-hostingbucket-dev.s3-website.ap-south-1.amazonaws.com',
      // OPTIONAL - Cookie path
          path: '/',
      // OPTIONAL - Cookie expiration in days
          expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: false
      },
        */
      // OPTIONAL - customized storage object
      storage: new AuthStorage(),

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH',

      /*
       * the section below is not used because we are using cognito identity js
       * underneath which simply does username, password authentication.
       * we can use the below only if we use hosted ui and launch web pages for
       * authentication.
       */
      /*
      oauth: {
        // Domain name
        domain: 'demo-app-0.auth.ap-south-1.amazoncognito.com',

        // Authorized scopes
        scope: ['phone', 'email', 'profile', 'openid'],

        // Callback URL
        redirectSignIn: 'http://localhost', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

        // Sign out URL
        // redirectSignOut : 'http://www.example.com/signout/', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

        // 'code' for Authorization code grant,
        // 'token' for Implicit grant
        // Note that REFRESH token will only be generated when the responseType is code
        responseType: 'token',

        // optional, for Cognito hosted ui specified options
        options: {
          // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
          AdvancedSecurityDataCollectionFlag: true,
        },

        // urlOpener: urlOpener
      },
      */
    },
    Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: true,
      // OPTIONAL - Allow recording session events. Default is true.
      autoSessionRecord: true,

      AWSPinpoint: {
        // OPTIONAL -  Amazon Pinpoint App Client ID
        appId: 'a83184598db34394af8afd1e52a90018',
        // OPTIONAL -  Amazon service region
        region: 'ap-south-1',
        // OPTIONAL -  Customized endpoint
        endpointId: 'XXXXXXXXXXXX',
        /** Since we are using autoSessionRecord we need to setup userId */
        endpoint: {
          // Customized userId
          userId: 'XXXXXXXXXXXX',
          optOut: 'NONE',
        },
        /**
        // OPTIONAL - Default Endpoint Information
        endpoint: {
            address: 'xxxxxxx', // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
            attributes: {
                // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
                hobbies: ['piano', 'hiking'],
            },
            channelType: 'APNS', // The channel type. Valid values: APNS, GCM
            demographic: {
                appVersion: 'xxxxxxx', // The version of the application associated with the endpoint.
                locale: 'xxxxxx', // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
                make: 'xxxxxx', // The manufacturer of the endpoint device, such as Apple or Samsung.
                model: 'xxxxxx', // The model name or number of the endpoint device, such as iPhone.
                modelVersion: 'xxxxxx', // The model version of the endpoint device.
                platform: 'xxxxxx', // The platform of the endpoint device, such as iOS or Android.
                platformVersion: 'xxxxxx', // The platform version of the endpoint device.
                timezone: 'xxxxxx', // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
            },
            location: {
                city: 'xxxxxx', // The city where the endpoint is located.
                country: 'xxxxxx', // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
                latitude: 0, // The latitude of the endpoint location, rounded to one decimal place.
                longitude: 0, // The longitude of the endpoint location, rounded to one decimal place.
                postalCode: 'xxxxxx', // The postal code or zip code of the endpoint.
                region: 'xxxxxx', // The region of the endpoint location. For example, in the United States, this corresponds to a state.
            },
            metrics: {
                // Custom metrics that your app reports to Amazon Pinpoint.
            },*/
        /** Indicates whether a user has opted out of receiving messages with one of the following values:
         * ALL - User has opted out of all messages.
         * NONE - Users has not opted out and receives all messages.
         */
        /*
        optOut: 'NONE',
        // Customized userId
        userId: 'XXXXXXXXXXXX',
        // User attributes
        userAttributes: {
            interests: ['football', 'basketball', 'AWS']
            // ...
        },
    },*/

        // Buffer settings used for reporting analytics events.
        // OPTIONAL - The buffer size for events in number of items.
        bufferSize: 1000,

        // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
        flushInterval: 60000, // 60s

        // OPTIONAL - The number of events to be deleted from the buffer when flushed.
        flushSize: 100,

        // OPTIONAL - The limit for failed recording retries.
        resendLimit: 5,
      },
    },
    API: {
      endpoints: [
        {
          name: 'ClientAPI',
          endpoint: process.env.REACT_APP_ADMIN_PORTAL === '1' ?
              'https://fz435hkag8.execute-api.ap-south-1.amazonaws.com/prod' :
              'https://l4mmcqn5i3.execute-api.ap-south-1.amazonaws.com/prod',
        },
        {
          name: 'WsAPI',
          endpoint: process.env.REACT_APP_ADMIN_PORTAL === '1' ?
              '' : 'wss://portal.smartyzedetect.com/prod',
        },
      ],
    },
  },
  signUpConfig: {
    header: 'Create your account',
    hideAllDefaults: true,
    defaultCountryCode: '91',
    signUpFields: [
      {
        label: 'Name',
        key: 'name',
        required: true,
        displayOrder: 1,
        type: 'string',
      },
      {
        label: 'Email',
        key: 'email',
        required: true,
        displayOrder: 2,
        type: 'email',
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        displayOrder: 3,
        type: 'password',
      },
      /*
      {
        label: 'Phone Number',
        key: 'phone_number',
        required: true,
        displayOrder: 4,
        type: 'string',
      },
      */
    ],
  },
  usernameAttributes: 'email',
  includeGreetings: false, // Render a sign out button once logged in
};

export default AwsConfig;
