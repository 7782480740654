import { v4 as uuidv4 } from 'uuid';
import logger from '../util/Logger';

const TAG = 'AppInstanceId';
const state = {
  instanceId: null,
};

class AppInstanceId {
  async getInstanceId() {
    if (state.instanceId) {
      return state.instanceId;
    }

    state.instanceId = await this.readValue('appId');
    if (state.instanceId) {
      return state.instanceId;
    }
    const id = uuidv4();
    await this.storeValues([['appId', id]]);
    state.instanceId = id;
    return id;
  }

  async clearInstanceId() {
    await this.deleteValues(['appId']);
    state.instanceId = null;
  }

  async readValue(key: string) {
    let val = null;
    try {
      val = window.localStorage.getItem(key);
    } catch (err) {
      logger.error(TAG, 'couldnt read value for:' + key, err);
    }
    return val;
  }

  async storeValues(pairs: string[][]) {
    try {
      for (let i = 0; i < pairs.length; i++) {
        window.localStorage.setItem(pairs[i][0], pairs[i][1]);
      }
      return true;
    } catch (err) {
      logger.error(TAG, 'couldnt store value for:' +
        JSON.stringify(pairs), err);
    }
    return false;
  }

  async deleteValues(keys: string[]) {
    try {
      for (let i = 0; i < keys.length; i++) {
        window.localStorage.removeItem(keys[i]);
      }
    } catch (err) {
      logger.error(TAG, 'couldnt clear keys:' +
        JSON.stringify(keys), err);
    }
  }
}

const appInstanceId = new AppInstanceId();
export default appInstanceId;
