import React, { useContext, createContext, useState } from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import PubSub from 'pubsub-js';
import AuthManager, { CREDS_PROVIDER, CREDS_STATUS } from '../state/auth/AuthManager';
import { Topics } from '../broadcast/Types';
import logger from '../util/Logger';

const LOG_TAG = 'UiAuth';

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext({
  user: null,
});

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const nativeSignin = async (email, password, otp) => {
    const res = await AuthManager.nativeSignin(email, password, otp);
    if (res.status) {
      setUser('SignedIn');
    }
    return res;
  };

  const federatedSignin = async (email, idToken) => {
    const res = await AuthManager.federatedSignIn(
      CREDS_PROVIDER.FEDERATED_GOOGLE, email, idToken);
    if (res.status) {
      setUser('SignedIn');
    }
    return res;
  };

  const signOut = async () => {
    await AuthManager.signOut();
    setUser(null);
  };

  PubSub.subscribe(Topics.AUTH_STATE, (data) => {
    if (data && data.state === 'signedOut') {
      setUser(null);
    } else if (data && data.state === 'signedIn' && !user) {
      // handle case where cache sign-in happened
      setUser('SignedIn');
    }
  });

  // Fetch the token from storage then navigate to our appropriate place
  const bootstrap = async () => {
    let needAuth = true;
    const haveCreds = await AuthManager.haveCreds();
    if (haveCreds) {
      const status = AuthManager.getCredsStatus();
      logger.debug(LOG_TAG, 'auth status is:' + status);
      needAuth = status !== CREDS_STATUS.VALID_TOKEN;
      if (!needAuth) {
        PubSub.publish(Topics.AUTH_STATE, { state: 'signedIn' });
      }
    }
    setUser(needAuth ? null : 'SignedIn');
    return needAuth;
  };

  const getUserData = async () => {
    if (user) {
      const data = await AuthManager.getUserData();
      return data.data;
    }
    return null;
  }

  return {
    user,
    bootstrap,
    nativeSignin,
    federatedSignin,
    getUserData,
    signOut,
  };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
