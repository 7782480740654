import logger from '../../util/Logger';
import Keychain from '../../util/Keychain';
import Utils from '../../util/Utils';

const DOMAIN = 'com.iotree.storage.amp';
const DATA_KEY = 'data';
const LOG_TAG = 'AuthStorage';

let dataMemory = {};
let dataChanged = true;

export class AuthStorage {
  syncPromise = null;

  storeData() {
    return Keychain.saveData(DOMAIN + '.' + DATA_KEY,
      JSON.stringify(dataMemory));
  }

  retrieveData() {
    return Keychain.getData(DOMAIN + '.' + DATA_KEY);
  }

  /**
   * This is used to set a specific item in storage
   */
  setItem(key: string, value) {
    dataMemory[key] = value;
    this.storeData().catch((err) => {
      logger.error(LOG_TAG, 'setItem:failed to store value:' + key,
        err);
    });
    return dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   */
  getItem(key: string) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key) ?
      dataMemory[key] : undefined;
  }

  /**
   * This is used to remove an item from storage
   */
  removeItem(key: string) {
    const status = delete dataMemory[key];
    if (status) {
      this.storeData().catch((err) => {
        logger.error(LOG_TAG, 'setItem:failed to del value:' + key,
          err);
      });
    }
    return status;
  }

  /**
   * This is used to clear the storage
   */
  clear() {
    dataMemory = {};
    Keychain.clearData(DOMAIN + '.' + DATA_KEY).catch((err) => {
      logger.error(LOG_TAG, 'clear:failed to clear values', err);
    });
    return dataMemory;
  }

  /**
   * Will sync the MemoryStorage data from persistent store
  */
  sync() {
    if (!this.syncPromise) {
      this.syncPromise = new Promise<void>((resolve, reject) => {
        if (!dataChanged) {
          logger.debug(LOG_TAG, 'not reading from persistent store');
          this.syncPromise = null;
          resolve();
          return;
        }
        const credsPromise = this.retrieveData();
        credsPromise
          .then(data => {
            const value = Utils.safeJsonParse(data);
            if (value) {
              dataMemory = value;
            } else {
              dataMemory = {};
            }
            dataChanged = false;
            this.syncPromise = null;
            resolve();
          })
          .catch(reason => {
            this.syncPromise = null;
            reject(reason);
          });
      });
    }
    return this.syncPromise;
  }
}
