import PubSub from 'pubsub-js';
import jwt from 'jsonwebtoken';
import { Topics } from '../broadcast/Types';
import AuthManager, { CREDS_STATUS } from '../state/auth/AuthManager';
import ApiManager from './ApiManager';
import WebSocketClient from '../third_party/websocket/websocket';
import awsconfig from '../state/auth/aws-config';
import logger from '../util/Logger';

const resolveEndpoint = (name: string) => {
  const endpoints = awsconfig.amplifyParams.API.endpoints;
  for (let i = 0; i < endpoints.length; i++) {
    if (endpoints[i].name === name) {
      return endpoints[i].endpoint;
    }
  }
  return null;
};

const WS_LOCAL_TEST = false;
const STUB_WS = process.env.REACT_APP_ADMIN_PORTAL === '1';

const API_ENDPOINT_NAME = 'WsAPI';
const WS_LOCAL_URL = 'ws://192.168.225.157:8443';
const WS_URL = WS_LOCAL_TEST ? WS_LOCAL_URL : resolveEndpoint(API_ENDPOINT_NAME);
const WS_CLOSE_CODE_NORMAL = 1000;
//const WS_CLOSE_TIMEOUT = 30000; // ms

const TAG = 'PersApiManager';

class PersistentApiManager {
  state = {
    ws: null,
    token: null,
    wssToken: null,
    mapOfCbs: {},
    stateCallbacks: new Set<(state: string) => void>(),
    cancelTimeoutId: null,
    subscribedToAuth: false,
    inForeground: false,
    inLiveLock: false,
  };

  subscribeToAuth = () => {
    if (this.state.subscribedToAuth) {
      return;
    }
    this.state.subscribedToAuth = true;
    const that = this;
    PubSub.subscribe(Topics.AUTH_STATE, async (msg, data) => {
      if (data) {
        if (data.state === 'signedOut') {
          await that.disconnect('Logout');
          that.state.token = null;
        } else if (data.state === 'credsRefreshed') {
          const creds = await AuthManager.getCreds();
          await that.connect(creds.token);
        }
      }
    });
  };

  onAppFg = async () => {
    this.state.inForeground = true;
    if (this.state.cancelTimeoutId) {
      clearTimeout(this.state.cancelTimeoutId);
      this.state.cancelTimeoutId = null;
    }
    this.subscribeToAuth();
    if (!this.isConnected()) {
      if (this.state.token) {
        await this.connect(this.state.token);
      } else {
        const haveCreds = await AuthManager.haveCreds();
        if (haveCreds) {
          const status = AuthManager.getCredsStatus();
          if (status === CREDS_STATUS.VALID_TOKEN) {
            const creds = await AuthManager.getCreds();
            await this.connect(creds.token);
          } else {
            logger.debug(TAG, 'appfg::creds not valid:', status);
          }
        } else {
          logger.debug(TAG, 'appfg::do not have creds');
        }
      }
    } else {
      logger.debug(TAG, 'ws already connected, nothing to do');
    }
  };

  onAppBg = () => {
    this.state.inForeground = false;
    if (this.state.cancelTimeoutId) {
      clearTimeout(this.state.cancelTimeoutId);
    }
    /**
    const that = this;
    this.state.cancelTimeoutId = setTimeout(() => {
      that.disconnect('Background');
    }, WS_CLOSE_TIMEOUT);
    **/
    //this.disconnect('Background');
    this.tryDisconnect('Background');
  };

  tryDisconnect = (reason: string) => {
    if (!this.state.inForeground && !this.state.inLiveLock) {
      this.disconnect(reason);
    }
  }

  acquireLiveLock = () => {
    this.state.inLiveLock = true;
  }

  releaseLiveLock = () => {
    this.state.inLiveLock = false;
    this.tryDisconnect('ReleaseLock');
  }

  onAuthSuccess = (token) => {
    if (!this.isConnected()) {
      this.connect(token);
    } else {
      logger.debug(TAG, 'AuthSs:ws already connected, leaving as is');
    }
  };

  disconnect = async (reason: string) => {
    let ws = this.state.ws;
    this.state.ws = null;
    if (ws) {
      logger.debug(TAG, 'Ws:disconnecting:' + reason);
      this.removeListeners(ws);
      ws.close(WS_CLOSE_CODE_NORMAL, reason);
      ws = null;
    }
  };

  onMessage = async (message: string) => {
    // TODO: Parse type and standardize format
    if (WS_LOCAL_TEST) {
      if (message.startsWith('HELLO')) {
        logger.debug(TAG, 'got back Hello, registered');
        return;
      } else if (message.startsWith('CALLED')) {
        logger.debug(TAG, 'got back call response, success');
        return;
      } else if (message.startsWith('ERROR')) {
        logger.debug(TAG, 'got error from server', message);
        return;
      } else if (message.startsWith('Disconnected')) {
        logger.debug(TAG, 'got disconnected from server', message);
        return;
      }
    }
    this.notifyCallback('*', JSON.parse(message));
  };

  refreshCreds = async () => {
    try {
      await AuthManager.refreshCreds();
      const creds = await AuthManager.getCreds();
      logger.debug(TAG, 'refreshed creds, connecting with new token');
      await this.connect(creds.token);
    } catch (authError) {
      // Auth manager will trigger UI updates
      logger.error(TAG, 'refresh creds failed', authError);
    }
  };

  setupListeners = (ws) => {
    const that = this;
    ws.onclose = () => {
      logger.debug(TAG, 'web socket is closed');
      that.notifyStateCallback('disconnected');
    };
    ws.onerror = (e) => {
      logger.debug(TAG, 'web socket error', e.message);
      if (typeof e.message === 'string' &&
        e.message.includes('401 Unauthorized')) {
        // stop re-tries and send out broadcast to re-sign-in
        that.disconnect('token-expired');
        that.refreshCreds();
      }
    };
    ws.onmessage = (data) => {
      logger.debug(TAG, 'web socket message arrived');
      that.onMessage(data.data);
    };
    ws.onopen = () => {
      logger.debug(TAG, 'web socket is open');
      that.notifyStateCallback('connected');
      if (WS_LOCAL_TEST) {
        ws.send('HELLO 1');
      }
    };
    ws.onreconnect = () => {
      logger.debug(TAG, 'web socked reconnected');
    };
  };

  removeListeners = (ws) => {
    ws.onclose = () => {
    };
    ws.onerror = (e) => {
    };
    ws.onmessage = (data) => {
    };
    ws.onopen = () => {
    };
    ws.onreconnect = () => {
    };
  };

  isConnected = () => {
    const ws = this.state.ws;
    return ws &&
      ws.readyState === WebSocketClient.OPEN;
  };

  getConnectUrl = (baseUrl: string, token: string) => {
    return baseUrl + '?token=' + token;
  }

  connect = async (token: string) => {
    await this.disconnect('Reconnect');
    if (STUB_WS) {
      return;
    }
    const wsToken = await this.getWssToken();
    if (!wsToken) {
      // retry if we couldnt connect
      setTimeout(() => {
        if (this.state.inForeground && !this.isConnected()) {
          this.connect(this.state.token);
        }
      }, 1000);
      return;
    }
    this.state.ws = new WebSocketClient(
      this.getConnectUrl(WS_URL, wsToken), [], {
      backoff: 'exponential',
      initialDelay: 100,
      maxDelay: 5000,
      connect: false,
    });
    this.state.token = token;
    this.setupListeners(this.state.ws);
    this.state.ws.open();
  };

  send = async (data: string) => {
    const ws = this.state.ws;
    if (ws) {
      ws.send(data);
      return true;
    } else {
      return false;
    }
  };

  addStateCallback = (cb: (state: string) => void) => {
    this.state.stateCallbacks.add(cb);
  }

  removeStateCallback = (cb: (state: string) => void) => {
    this.state.stateCallbacks.delete(cb);
  }

  notifyStateCallback = (state: string) => {
    this.state.stateCallbacks.forEach(cb => {
      cb(state);
    });
  }

  addCallback = (type: string, cb: Function) => {
    let cbs = this.state.mapOfCbs[type];
    if (!cbs) {
      cbs = new Set();
      this.state.mapOfCbs[type] = cbs;
    }
    cbs.add(cb);
  };

  removeCallback = (type: string, cb: Function) => {
    const cbs = this.state.mapOfCbs[type];
    if (cbs) {
      cbs.delete(cb);
    }
  };

  notifyCallback = (type: string, data: any) => {
    const cbs = this.state.mapOfCbs[type];
    if (cbs) {
      cbs.forEach((cb) => {
        cb(type, data);
      });
    }
  };

  getWssToken = async () => {
    let haveValidToken = false;
    if (this.state.wssToken) {
      try {
        const decoded = jwt.decode(this.state.wssToken,
            {json: true});
        if (decoded && typeof decoded.exp === 'number') {
          const now = Math.floor(Date.now() / 1000);
          if (now < (decoded.exp - 60)) {
            haveValidToken = true;
          }
        }
      } catch (err) {
        logger.error(TAG, 'GwsT:token parse failed:', err);
      }
    }
    if (!haveValidToken) {
      const token = await this.fetchWssToken();
      this.state.wssToken = token;
    }
    return this.state.wssToken;
  }

  fetchWssToken = async () => {
    try {
      const res = await ApiManager.get('/app/wsauth');
      if (res.status === 200) {
        return res.data.token;
      }
    } catch (err) {
      logger.error(TAG, 'Failed to get connect token');
    }
    return null;
  }
}

const instance = new PersistentApiManager();

export default instance;
