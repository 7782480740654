import ApiManager from '../api/ApiManager';
import logger from '../util/Logger';
import DataManager from './DataManager';

export enum SubscriptionStatus {
  UNKNOWN = 0,
  CREATED = 1,
  VERIFIED = 2,
  ACTIVE = 3,
  PAYMENT_PENDING = 4,
  PAYMENT_FAILED = 5,
  CANCELLED = 6,
  EXPIRED = 7,
  COMPLETED = 8,
  PAUSED = 9,
}

export enum PlanCurrency {
  INR = 'INR',
}

export enum PlanPeriod {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  yearly = 'yearly',
}

export interface Plan {
  id: string,
  amount: number,
  currency: PlanCurrency,
  period: PlanPeriod,
  interval: number,
  duration: number,
  trial_days: number,
  is_live: number,
  is_promotion: number,
  ui_name_id: string,
  ui_desc_id: string,
  ui_features_id: string,
}

export interface DeviceId {
  id: string,
  source: number,
}

export interface DeviceFeatureSet {
  motionDetection: number,
  motionZones: number,
  personDetection: number,
  babyCryDetection: number,
  motionSnapshot: number,
  clipRecord: number,
  streamLocal: number,
  streamRemote: number,
  clipHistory: string,
  eventHistory: string,
  audioDeterNum: number, // number per device
  audioDeterCustom: number, // number per device
  vehicleDetection: number,
  socialDistanceDetection: number,
  maskDetection: number,
  statsHistory: string,
}

export interface ServiceFeatureSet {
  email: number,
  push: number,
  contactSms: number,
  alexaSkill: number,
  numDevices: number,
  videoShare: number,
  usersPerSite: number,
  eventsPerDay: number,
  audioDeterNum: number, // number per site
  audioDeterCustom: number, // number per site
  desktopAppAllowed: number,
}

export interface FeatureSet {
  version: number,
  device: DeviceFeatureSet,
  service: ServiceFeatureSet,
}

export interface Subscription {
  id: string,
  current_status: SubscriptionStatus,
  plan_id: string,
  plan_multiplier: number,
  trial_expires_at: string,
  expires_at: string,
  cancel_at: string,
  devices: DeviceId[],
  featureMap: FeatureSet,
}

export interface SubscriptionResult {
  plans: Plan[],
  subscriptions: Subscription[],
  defaultFeatures: FeatureSet,
}

export interface DeviceDesc {
  device: string,
  source: number,
}

export interface UpdateSubscription {
  plan_id?: string,
  devices?: DeviceDesc[],
}

const TAG = 'SubscriptionManager';
const REFRESH_INTERVAL = 5 * 60 * 1000; // ms

const state = {
  lastLoadTimes: {},
};

class SubscriptionManager {
  async loadSubscriptions(siteId: string): Promise<SubscriptionResult> {
    let subs = null;
    try {
      subs = await this.getSubscriptions(siteId);
      state.lastLoadTimes[siteId] = Date.now();
    } catch (err) {
      logger.debug(TAG, 'loadSubs:failed to load subs', err);
    }
    let allSubs = null;
    try {
      allSubs = await DataManager.getSubscriptions();
      if (subs) {
        allSubs[siteId] = subs;
        await DataManager.storeSubscriptions(allSubs);
      } else {
        subs = allSubs[siteId];
      }
    } catch (err) {
      logger.debug(TAG, 'loadSubs:failed to read/store subs', err);
    }
    return subs;
  }

  async peekSubscriptions(siteId: string, dontLoad: boolean) {
    if (state.lastLoadTimes[siteId] &&
      (Date.now() - state.lastLoadTimes[siteId]) < REFRESH_INTERVAL) {
      const allSubs = await DataManager.getSubscriptions();
      return allSubs[siteId];
    } else {
      if (dontLoad) {
        const allSubs = await DataManager.getSubscriptions();
        return allSubs[siteId];
      }
      return await this.loadSubscriptions(siteId);
    }
  }

  async getSubscriptions(siteId: string): Promise<SubscriptionResult> {
    try {
      const res = await ApiManager.get('/subscriptions/status', {
        site_id: siteId,
      });
      return res.data;
    } catch (err) {
      logger.debug(TAG, 'getSubs:failed to get subs', err);
      return null;
    }
  }

  clear() {
    state.lastLoadTimes = {};
  }

  /*
  async updateSubscription(subId: string, req: UpdateSubscription) {
    try {
      await ApiManager.put('/subscriptions/' + subId, req);
      return true;
    } catch (err) {
      logger.debug(TAG, 'updateSub:failed to update sub', err);
      return false;
    }
  }

  async deleteSubscription(subId: string, immediate: boolean) {
    try {
      const res = await ApiManager.delete('/subscriptions/' + subId, {
        immediate: immediate ? 1 : 0,
      });
      return res.status === 200;
    } catch (err) {
      logger.debug(TAG, 'deleteSub:failed', err);
      return false;
    }
  }

  async refreshSubscription(subId: string, verifyData: string) {
    try {
      const res = await ApiManager.post(
        '/subscriptions/' + subId + '/refresh',
        verifyData,
      );
      return res.status === 200 || res.status === 204;
    } catch (err) {
      logger.debug(TAG, 'refreshSub:failed to refresh sub', err);
      return false;
    }
  }

  async createSubscription(siteId: string, planId: string) {
    try {
      const res = await ApiManager.post('/subscriptions', {
        site_id: siteId,
        plan_id: planId,
      });
      return res.data;
    } catch (err) {
      logger.debug(TAG, 'createSub:failed to create sub', err);
      return null;
    }
  }
  */

  parseDurationToDays(duration: string) {
    const parts = duration.split(',', 2);
    if (parts.length === 2) {
      if (parts[1] === 'd') {
        return parseInt(parts[0], 10);
      }
    }
    return -1;
  }

  // Helper methods to parse out features from subs
  areCustomSoundsAllowedForSite(subs: SubscriptionResult): boolean {
    const allowed = subs.subscriptions.some(s => {
      if (s.featureMap.service.audioDeterCustom > 0) {
        return true;
      }
      return false;
    });
    return allowed;
  }

  getNumInvitedUsersAllowedForSite(subs: SubscriptionResult): number {
    let count = 0;
    subs && subs.subscriptions.forEach(s => {
      count += s.featureMap.service.usersPerSite;
    });
    return count;
  }

  isBabyCryDetectionAllowedForSite(subs: SubscriptionResult): boolean {
    const allowed = subs.subscriptions.some(s => {
      if (s.featureMap.device.babyCryDetection > 0) {
        return true;
      }
      return false;
    });
    return allowed;
  }

  isPersonDetectionAllowedForSite(subs: SubscriptionResult): boolean {
    const allowed = subs.subscriptions.some(s => {
      if (s.featureMap.device.personDetection > 0) {
        return true;
      }
      return false;
    });
    return allowed;
  }

  isVehicleDetectionAllowedForSite(subs: SubscriptionResult): boolean {
    const allowed = subs.subscriptions.some(s => {
      if (s.featureMap.device.vehicleDetection > 0) {
        return true;
      }
      return false;
    });
    return allowed;
  }

  isSocialDistDetectionAllowedForSite(subs: SubscriptionResult): boolean {
    const allowed = subs.subscriptions.some(s => {
      if (s.featureMap.device.socialDistanceDetection > 0) {
        return true;
      }
      return false;
    });
    return allowed;
  }

  isMaskDetectionAllowedForSite(subs: SubscriptionResult): boolean {
    const allowed = subs.subscriptions.some(s => {
      if (s.featureMap.device.maskDetection > 0) {
        return true;
      }
      return false;
    });
    return allowed;
  }

  isStatsAllowedForSite(subs: SubscriptionResult): boolean {
    const allowed = subs.subscriptions.some(s => {
      if (s.featureMap.device.maskDetection > 0 ||
          s.featureMap.device.socialDistanceDetection > 0 ||
          s.featureMap.device.personDetection > 0) {
        return true;
      }
      return false;
    });
    return allowed;
  }

  getFeaturesetForDevice(subs: SubscriptionResult,
    deviceId: string): FeatureSet {
    let fs: FeatureSet = subs.defaultFeatures;
    subs.subscriptions.some(s => {
      const found = s.devices.some(d => {
        return d.id === deviceId;
      });
      if (found) {
        fs = s.featureMap;
      }
      return found;
    });
    return fs;
  }

  getSubscriptionForDevice(subs: SubscriptionResult,
    deviceId: string): Subscription {
    let sub: Subscription = null;
    subs.subscriptions.some(s => {
      const found = s.devices.some(d => {
        return d.id === deviceId;
      });
      if (found) {
        sub = s;
      }
      return found;
    });
    return sub;
  }
};

const subsManager = new SubscriptionManager();
export default subsManager;
