import ApiManager from '../api/ApiManager';
import SubscriptionManager, {
  FeatureSet, Plan, SubscriptionResult,
} from '../state/SubscriptionManager';
import { MotionArea, ObjectDetectionEnv,
  DistanceBoundaryPoint, DistanceSeparationPoint,
} from '../state/DeviceConstants';
import logger from '../util/Logger';
import UserManager from '../state/UserManager';
import RuleManager, { Rule } from '../state/RuleManager';
import DeviceManager from '../state/DeviceManager';
import SoundManager, { Sound } from '../state/SoundManager';

export interface UserIdChangeCallback {
  onUserIdChanged: (userGid: string, email: string) => void,
}

interface UiUserDataInterface {
  getUserEmail: () => string,
  getUserGid: () => string,
  setUserDetails: (email: string, userGid: string) => void,
  addChangeCallback: (cb: UserIdChangeCallback) => void,
  removeChangeCallback: (cb: UserIdChangeCallback) => void,
  clearData: (clearUserData: boolean) => Promise<void>,
  loadSites: (userGid: string) => Promise<any[]>,
  getSites: (userGid: string) => any[],
  getCurrentSiteId: () => string,
  setCurrentSiteId: (siteId: string) => void,
  createSite: (name: string, pinCode: string, st: string) => Promise<any>,
  getPromotions: () => Plan[],
  loadPromotions: () => Promise<Plan[]>,
  getDevices: (siteId: string) => any[],
  loadDevices: (siteId: string) => Promise<any[]>,
  getSubscription: (siteId: string) => SubscriptionResult,
  loadSubscriptions: (siteId: string, sync: boolean) => Promise<SubscriptionResult>,
  assignSubscription: (siteId: string, userEmail: string,
    promoGid: string, durationDays: number) => Promise<string>,
  extendSubscription: (subGid: string, expireDate: Date,
    autoAddDevices: boolean) => Promise<boolean>,
  cancelSubscription: (subGid: string, expireDate: Date,
    autoRemoveDevices: boolean, immediate: boolean) => Promise<boolean>,
  getFeaturesForSub: (subGid: string) => Promise<FeatureSet>,
  setFeaturesForSub: (subGid: string, features: FeatureSet,
    endDate: Date) => Promise<boolean>,
  getCurrentSubId: () => string,
  setCurrentSubId: (subId: string) => void,
  editSubscription: (subGid: string, planGid: string,
      devices: any[]) => Promise<boolean>,
  getRules: (siteId: string) => Rule[],
  loadRules: (siteId: string) => Promise<Rule[]>,
  getSounds: (siteId: string) => Sound[],
  loadSounds: (siteId: string) => Promise<Sound[]>,
  updateRule: (ruleId: string, rule: Rule) => Promise<boolean>,
  getDeviceState: (deviceId: string) => any,
  loadDeviceState: (deviceId: string, loadConfig: boolean) => Promise<any>,
  triggerBugreport: (deviceId: string) => Promise<boolean>,
  checkForUpdates: (deviceId: string) => Promise<boolean>,
  setDeviceRule: (deviceId: string, mode: number,
      ruleId?: string) => Promise<boolean>,
  setMotionSettings: (deviceId: string, enabled: boolean,
    sensitivity: number, motionAreas: Array<MotionArea>) => Promise<boolean>,
  setObjectSettings: (deviceId: string, enabled: boolean,
    env: ObjectDetectionEnv, boundary: DistanceBoundaryPoint[],
    separation: DistanceSeparationPoint[], useMotionZones: boolean,
    onlyMovingObjects: boolean) => Promise<boolean>,
}

const TAG = 'UiUserData';

const state = {
  email: '',
  userGid: '',
  currentSiteId: '',
  sites: [],
  siteDevices: {},
  siteSubs: {},
  promotions: [],
  currentSubId: '',
  siteRules: {},
  siteSounds: {},
  deviceStates: {},
  changeCbs: new Set<UserIdChangeCallback>(),
};

class UiUserData implements UiUserDataInterface {
  getUserEmail() {
    return state.email;
  }

  getUserGid() {
    return state.userGid;
  }

  setUserDetails(email: string, userGid: string) {
    state.email = email;
    state.userGid = userGid;
    this.notifyChangeCallback(userGid, email);
  }

  addChangeCallback(cb: UserIdChangeCallback) {
    state.changeCbs.add(cb);
  }

  removeChangeCallback(cb: UserIdChangeCallback) {
    state.changeCbs.delete(cb);
  }

  async clearData(clearUserData: boolean) {
    if (clearUserData) {
      state.email = '';
      state.userGid = '';
    }
    state.currentSiteId = '';
    state.sites = [];
    state.currentSubId = '';
    state.siteSubs = {};
    state.siteDevices = {};
    state.siteRules = {};
    state.siteSounds = {};
    state.deviceStates = {};
  }

  getCurrentSiteId() {
    return state.currentSiteId;
  }

  setCurrentSiteId(siteId: string) {
    state.currentSiteId = siteId;
  }

  getSites() {
    return state.sites;
  }

  async loadSites() {
    try {
      const response = await ApiManager.get('/sites');
      if (response.status === 200) {
        state.sites = response.data;
        return state.sites;
      }
    } catch (err) {
      logger.error(TAG, 'loading sites failed:', err);
    }
    state.sites = [];
    return state.sites;
  }

  async createSite(name: string, pinCode: string, st: string) {
    const siteModel = {
      name: name,
      address: '',
      state: st,
      post_code: pinCode,
      country_code: 'IN',
      site_info: {},
      tz: 'Asia/Kolkata',
    };
    try {
      const response = await ApiManager.post('/sites', siteModel);
      if (response.status === 201) {
        (siteModel as any).id = response.data.id;
        return siteModel;
      }
    } catch (err) {
      logger.error(TAG, 'create site failed:', err);
    }
    return null;
  }

  async loadPromotions() {
    try {
      const res = await ApiManager.get('/plans/promotions');
      state.promotions = res.data;
      return state.promotions;
    } catch (err) {
      logger.debug(TAG, 'failed to get promos:', err);
      return null;
    }
  }

  getDevices(siteId: string) {
    return state.siteDevices[siteId];
  }

  async loadDevices(siteId: string) {
    try {
      const res = await ApiManager.get('/devices', {
        site_id: siteId,
      });
      if (res.status === 200) {
        state.siteDevices[siteId] = res.data;
        return res.data;
      }
    } catch (err) {
      logger.debug(TAG, 'failed to get devices:' + siteId,
          err);
    }
    return null;
  }

  getPromotions() {
    return state.promotions;
  }

  getSubscription(siteId: string) {
    return state.siteSubs[siteId];
  }

  async loadSubscriptions(siteId: string, sync: boolean) {
    try {
      let res: SubscriptionResult;
      if (sync) {
        res = await SubscriptionManager.loadSubscriptions(siteId);
      } else {
        res = await SubscriptionManager.peekSubscriptions(
            siteId, false);
      }
      state.siteSubs = res;
      return res;
    } catch (err) {
      logger.debug(TAG, 'loadSubs:failed to load subs:' + siteId,
          err);
      return null;
    }
  }

  async assignSubscription(siteId: string, userEmail: string,
      promoGid: string, durationDays: number) {
    try {
      const res = await ApiManager.post(
          '/userinvites/promotions/assign', {
        site_id: siteId,
        email: userEmail,
        target_res: 3, // PROMO
        target_res_id: promoGid,
        promo_period: 'daily',
        promo_duration: durationDays,
      }, {
        tenant_gid: UserManager.getTenantId(),
      });
      return res.status === 200 ? res.data.sub_id : null;
    } catch (err) {
      logger.debug(TAG, 'assignSub:failed to assign sub for:' + siteId,
          err);
      return null;
    }
  }

  async extendSubscription(subGid: string, expireDate: Date,
      autoAddDevices: boolean) {
    try {
      const res = await ApiManager.post(
          '/subscriptions/' + subGid + '/extend' , {
          expire_date: expireDate.toISOString(),
          auto_add: autoAddDevices,
      });
      return res.status === 200;
    } catch (err) {
      logger.debug(TAG, 'extendSub:failed to extend sub:' + subGid,
          err);
      return false;
    }
  }

  async cancelSubscription(subGid: string, expireDate: Date,
      autoRemoveDevices: boolean, immediate: boolean) {
    try {
      const res = await ApiManager.post(
          '/subscriptions/' + subGid + '/cancel' , {
            expire_date: expireDate.toISOString(),
            auto_remove: autoRemoveDevices,
            immediate: immediate,
      });
      return res.status === 200;
    } catch (err) {
      logger.debug(TAG, 'cancelSub:failed to cancel sub:' + subGid,
          err);
      return false;
    }
  }

  async getFeaturesForSub(subGid: string) {
    try {
      const res = await ApiManager.get('/subscriptions/' +
          subGid + '/features');
      return res.data;
    } catch (err) {
      logger.debug(TAG, 'failed to get ftrs:' + subGid, err);
      return null;
    }
  }

  async setFeaturesForSub(subGid: string, features: FeatureSet,
      endDate: Date) {
    try {
      const res = await ApiManager.put('/subscriptions/' +
          subGid + '/features', {
            start_date: Date.now(),
            end_date: endDate.getTime(),
            features: features,
          });
      return res.status === 200;
    } catch (err) {
      logger.debug(TAG, 'failed to set ftrs:' + subGid, err);
      return false;
    }
  }

  async editSubscription(subGid: string, planGid: string,
      devices: any[]) {
    try {
      const res = await ApiManager.put(
          '/subscriptions/' + subGid , {
          plan_id: planGid,
          devices: devices,
      });
      return res.status === 200 || res.status === 204;
    } catch (err) {
      logger.debug(TAG, 'editSub:failed to edit sub:' + subGid,
          err);
      return false;
    }
  }

  getCurrentSubId() {
    return state.currentSubId;
  }

  setCurrentSubId(subId: string) {
    state.currentSubId = subId;
  }

  getRules(siteId: string) {
    const rules = state.siteRules[siteId];
    if (!rules) {
      return [];
    }
    return rules;
  }

  async loadRules(siteId: string) {
    const stat = await RuleManager.getRules(siteId);
    if (stat.status) {
      state.siteRules[siteId] = stat.data;
      return stat.data;
    }
    state.siteRules[siteId] = [];
    return [];
  }

  async updateRule(ruleId: string, rule: Rule) {
    const stat = await RuleManager.updateRule(ruleId, rule);
    return stat.status;
  }

  getSounds(siteId: string) {
    const res = state.siteSounds[siteId];
    if (res) {
      return res;
    } else {
      return [];
    }
  }

  async loadSounds(siteId: string) {
    const res = await SoundManager.getSounds(siteId);
    if (res) {
      state.siteSounds[siteId] = res.sounds;
      return res.sounds;
    } else {
      state.siteSounds[siteId] = [];
      return [];
    }
  }

  getDeviceState(deviceId: string) {
    return state.deviceStates[deviceId];
  }

  async loadDeviceState(deviceId: string, loadConfig: boolean) {
    const stat = await DeviceManager.getDeviceData(deviceId);
    let cstat;
    if (loadConfig) {
      cstat = await DeviceManager.getDeviceConfig(deviceId);
    }
    if (stat.status) {
      state.deviceStates[deviceId] = stat.data;
      if (loadConfig && cstat && cstat.status) {
        state.deviceStates[deviceId].config = cstat.data.config;
      }
    }
    return stat.data;
  }

  async triggerBugreport(deviceId: string) {
    const res = await DeviceManager.triggerBugreport(deviceId);
    return res.status;
  }

  async checkForUpdates(deviceId: string) {
    const res = await DeviceManager.updateDeviceSoftware(deviceId);
    return res.status;
  }

  async setDeviceRule(deviceId: string, mode: number, ruleId?: string) {
    const res = await DeviceManager.setDeviceMode(
        deviceId, mode, ruleId);
    return res.status;
  }

  async setMotionSettings(deviceId: string, enabled: boolean,
      sensitivity: number, motionAreas: Array<MotionArea>) {
    const res = await DeviceManager.setMotionSettings(deviceId,
        enabled, sensitivity, motionAreas);
    return res.status;
  }

  async setObjectSettings(deviceId: string, enabled: boolean,
      env: ObjectDetectionEnv, boundary: DistanceBoundaryPoint[],
      separation: DistanceSeparationPoint[], useMotionZones: boolean,
      onlyMovingObjects: boolean) {
    const res = await DeviceManager.setObjectSettings(deviceId,
        enabled, env, boundary, separation, useMotionZones,
        onlyMovingObjects);
    return res.status;
  }

  private notifyChangeCallback(userGid: string, email: string) {
    state.changeCbs.forEach(cb => {
      cb.onUserIdChanged(userGid, email);
    });
  }
}

class UiUserDataStub implements UiUserDataInterface {
  getUserEmail() {
    return null;
  }

  getUserGid() {
    return null;
  }

  setUserDetails(email: string, userGid: string) {
  }

  addChangeCallback(cb: UserIdChangeCallback) {
  }

  removeChangeCallback(cb: UserIdChangeCallback) {
  }

  async clearData(clearUserData: boolean) {
  }

  getCurrentSiteId() {
    return null;
  }

  setCurrentSiteId() {}

  getSites() {
    return [];
  }

  async loadSites() {
    return [];
  }

  async createSite(name: string, pinCode: string, st: string) {
    return null;
  }

  getPromotions() {
    return [];
  }

  async loadPromotions() {
    return [];
  }

  getDevices(siteId: string) {
    return [];
  }

  async loadDevices(siteId: string) {
    return [];
  }

  getSubscription(siteId: string) {
    return null;
  }

  async loadSubscriptions(siteId: string, sync: boolean) {
    return null;
  }

  async assignSubscription(siteId: string, userEmail: string,
      promoGid: string, durationDays: number) {
    return null;
  }

  async extendSubscription(subGid: string, expireDate: Date,
      autoAddDevices: boolean) {
    return false;
  }

  async cancelSubscription(subGid: string, expireDate: Date,
      autoRemoveDevices: boolean, immediate: boolean) {
    return false;
  }

  async getFeaturesForSub(subGid: string) {
    return null;
  }

  async setFeaturesForSub(subGid: string, features: FeatureSet,
      endDate: Date) {
    return false;
  }

  async editSubscription(subGid: string, planGid: string,
      devices: any[]) {
    return false;
  }

  getCurrentSubId() {
    return null;
  }

  setCurrentSubId(subId: string) {}

  getRules(siteId: string) {
    return [];
  }

  async loadRules(siteId: string) {
    return [];
  }

  async updateRule(ruleId: string, rule: Rule) {
    return false;
  }

  getDeviceState(deviceId: string) {
    return null;
  }

  async loadDeviceState(deviceId: string, loadConfig: boolean) {
    return null;
  }

  getSounds(siteId: string) {
    return [];
  }

  async loadSounds(siteId: string) {
    return [];
  }

  async triggerBugreport(deviceId: string) {
    return false;
  }

  async checkForUpdates(deviceId: string) {
    return false;
  }

  async setDeviceRule(deviceId: string, mode: number, ruleId?: string) {
    return false;
  }

  async setMotionSettings(deviceId: string, enabled: boolean,
      sensitivity: number, motionAreas: Array<MotionArea>) {
    return false;
  }

  async setObjectSettings(deviceId: string, enabled: boolean,
      env: ObjectDetectionEnv, boundary: DistanceBoundaryPoint[],
      separation: DistanceSeparationPoint[], useMotionZones: boolean,
      onlyMovingObjects: boolean) {
    return false;
  }
}

const instance = process.env.REACT_APP_ADMIN_PORTAL === '1' ?
    new UiUserData() : new UiUserDataStub();
export default instance;
