import ApiManager from '../api/ApiManager';
import Logger from '../util/Logger';
import { MotionSensitivity } from './DeviceConstants';

const LOG_TAG = 'RuleManager';

export interface RuleTriggerMotionSource {
  sensitivity: MotionSensitivity,
}

export interface RuleTriggerSource {
  id: 'motion' | 'sound' | 'object' | 'vehicle' | 'distancing' | 'mask',
  data?: RuleTriggerMotionSource,
}

export interface RuleTrigger {
  sources: Array<RuleTriggerSource>,
}

export interface RuleSoundAction {
  id: string,
  d: number,
  r: number,
  ri: number,
  v: number,
}

export interface RuleActionTarget {
  id: 'record' | 'sound' | 'srecord' | 'orecord' | 'mrecord',
  // record options
  duration?: number,
  // sound options
  mse?: boolean,
  ose?: boolean,
  vse?: boolean,
  sdse?: boolean,
  mdse?: boolean,
  sounds?: Array<RuleSoundAction>,
}

export interface RuleAction {
  targets: Array<RuleActionTarget>,
}

export interface RuleNotificationSetting {
  source: 'motion' | 'object' | 'sound' | 'record' |
  'vehicle' | 'distancing' | 'anomaly' | 'mask',
  push: number,
  email: number,
}

export interface RuleNotification {
  settings: Array<RuleNotificationSetting>,
}

export interface Rule {
  site_id: string,
  rule_id?: string,
  rule_name: string,
  triggers: RuleTrigger,
  actions: RuleAction,
  notifications: RuleNotification,
}

export interface GetRulesResult {
  status: boolean,
  data: Array<Rule>,
}

class Helper {
  static upgradeRuleIfNeeded(rule: Rule): Rule {
    if (rule && rule.notifications &&
      Array.isArray(rule.notifications.settings)) {
      let haveSoundNotifs = false, haveVehicleNotifs = false;
      let haveSocialNotifs = false, haveAnomalyNotifs = false;
      let haveMaskNotifs = false;
      rule.notifications.settings.forEach(n => {
        if (n.source === 'sound') {
          haveSoundNotifs = true;
        } else if (n.source === 'vehicle') {
          haveVehicleNotifs = true;
        } else if (n.source === 'distancing') {
          haveSocialNotifs = true;
        } else if (n.source === 'anomaly') {
          haveAnomalyNotifs = true;
        } else if (n.source === 'mask') {
          haveMaskNotifs = true;
        }
      });
      if (!haveSoundNotifs) {
        rule.notifications.settings.push({
          source: 'sound',
          push: 1,
          email: 1,
        });
      }
      if (!haveVehicleNotifs) {
        rule.notifications.settings.push({
          source: 'vehicle',
          push: 1,
          email: 1,
        });
      }
      if (!haveSocialNotifs) {
        rule.notifications.settings.push({
          source: 'distancing',
          push: 1,
          email: 1,
        });
      }
      if (!haveAnomalyNotifs) {
        rule.notifications.settings.push({
          source: 'anomaly',
          push: 1,
          email: 0,
        });
      }
      if (!haveMaskNotifs) {
        rule.notifications.settings.push({
          source: 'mask',
          push: 1,
          email: 1,
        });
      }
      // remove duplicates for notifs as we introduced a bug
      // which created duplicates
      const foundSources = {};
      for (let i = rule.notifications.settings.length - 1;
        i >= 0; i--) {
        const setting = rule.notifications.settings[i];
        if (!foundSources[setting.source]) {
          foundSources[setting.source] = 1;
        } else {
          // duplicate entry, remove this one
          rule.notifications.settings.splice(i, 1);
        }
      }
    }
    return rule;
  }

  static upgradeRulesIfNeeded(rules: Rule[]): Rule[] {
    if (rules) {
      rules.forEach(r => {
        Helper.upgradeRuleIfNeeded(r);
      });
    }
    // sort rules by name
    rules.sort((a, b) => {
      const cmp = a.rule_name.localeCompare(b.rule_name);
      if (cmp < 0) {
        return -1;
      } else if (cmp > 0) {
        return 1;
      }
      return 0;
    });
    return rules;
  }
}

class RuleManager {
  async getRules(siteId: string): Promise<GetRulesResult> {
    try {
      const response = await ApiManager.get('/rules/', {
        site_id: siteId,
      });
      if (response.status !== 200) {
        return { status: false, data: null };
      } else {
        return {
          status: true,
          data: Helper.upgradeRulesIfNeeded(response.data),
        };
      }
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to get rules', error);
      return { status: false, data: null };
    }
  }

  async createRule(siteId: string, model: Rule) {
    try {
      const response = await ApiManager.post('/rules', model, {
        site_id: siteId,
      });
      if (response.status !== 201) {
        return { status: false, data: null };
      } else {
        return { status: true, data: response.data };
      }
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to create rule', error);
      let errCode = null, statusCode = null;
      if (error.response && error.response.data &&
        error.response.data.code) {
        errCode = error.response.data.code;
      }
      if (error.response && error.response.status) {
        statusCode = error.response.status;
      }
      return {
        status: false, data: null, code: statusCode,
        errCode: errCode,
      };
    }
  }

  async updateRule(ruleId: string, model: Rule) {
    try {
      const response = await ApiManager.put('/rules/' + ruleId,
        model);
      if (response.status !== 200) {
        return { status: false, data: null };
      } else {
        return { status: true, data: response.data };
      }
    } catch (error) {
      Logger.error(LOG_TAG, 'failed to update rule', error);
      let errCode = null, statusCode = null;
      if (error.response && error.response.data &&
        error.response.data.code) {
        errCode = error.response.data.code;
      }
      if (error.response && error.response.status) {
        statusCode = error.response.status;
      }
      return {
        status: false, data: null, code: statusCode,
        errCode: errCode,
      };
    }
  }

  async deleteRule(ruleId: string, siteId: string) {
    try {
      const response = await ApiManager.delete('/rules/' + ruleId, {
        site_id: siteId,
      });
      if (response.status !== 200) {
        return { status: false, data: null, code: response.status };
      } else {
        return { status: true, data: response.data };
      }
    } catch (error) {
      Logger.error(LOG_TAG, 'DelR:failed to delete rule', error);
      let errCode = null, statusCode = null;
      if (error.response && error.response.data &&
        error.response.data.code) {
        errCode = error.response.data.code;
      }
      if (error.response && error.response.status) {
        statusCode = error.response.status;
      }
      return {
        status: false, data: null, code: statusCode,
        errCode: errCode,
      };
    }
  }
}

const rmInstance = new RuleManager();
export default rmInstance;
