import ApiManager from '../api/ApiManager';
import logger from '../util/Logger';

export enum AccessLevel {
  GUEST = 1,
  FULL_ACCESS = 2,
  ADMIN = 100,
  NO_ACCESS = 500,
}

export enum InviteAction {
  ACCEPT = 4,
  REJECT = 5,
}

export enum TargetResource {
  SITE = 1,
  DEVICE = 2,
  PROMOTION = 3,
}

export interface DevicePermission {
  device_id: string,
  device_name: string,
  allowed: boolean,
}

export interface SiteUserPermission {
  user_id: string,
  user_name: string,
  email: string,
  access_level: AccessLevel,
  permissions: DevicePermission[],
}

export interface DevicePermissionRequest {
  device_id: string,
  allowed: boolean,
}

export interface UserPermissionRequest {
  user_id: string,
  access_level: AccessLevel,
  permissions: DevicePermissionRequest[],
}

interface BaseActiveInviteResult {
  id: string,
  resType: TargetResource,
  expires: string,
}

interface SiteInviteResult extends BaseActiveInviteResult {
  resName: string, // name of site invited to
  srcUserName: string, // name of user who invited
}

interface PromotionInviteResult extends BaseActiveInviteResult {
  resName: string, // name of promotion
  resDesc: string, // description of promotion
}

export type ActiveInviteResult = SiteInviteResult | PromotionInviteResult;

export interface PendingInviteResult {
  id: string,
  resName: string,
  srcUserName: string,
  targetUserName: string,
  targetUserEmail: string,
  expires: string,
}

const TAG = 'PermissionsManager';

class PermissionsManager {
  async getUsersForSite(siteId: string):
    Promise<SiteUserPermission[]> {
    try {
      const res = await ApiManager.get('/permissions', {
        site_id: siteId,
      });
      return res.data;
    } catch (err) {
      logger.debug(TAG, 'getUsersFs:failed to get users', err);
      return null;
    }
  }

  async applyPermissionsForSite(siteId: string,
    perms: UserPermissionRequest[]) {
    try {
      const res = await ApiManager.post('/permissions/manage', perms, {
        site_id: siteId,
      });
      logger.debug(TAG, 'aPForS:returned status:', res.status);
      return res.status === 200;
    } catch (err) {
      logger.debug(TAG, 'aPForS:failed to apply perms:', err);
      return false;
    }
  }

  async inviteUserToSite(siteId: string, userName: string,
    userEmail: string, accessLevel: AccessLevel, devices: string[]) {
    try {
      const res = await ApiManager.post('/userinvites', {
        name: userName,
        email: userEmail,
        target_res: TargetResource.SITE,
        access_level: accessLevel,
        target_res_id: siteId,
        devices: devices,
      });
      logger.debug(TAG, 'invUToS:returned status:', res.status);
      return res.data;
    } catch (err) {
      logger.debug(TAG, 'invUToS:failed to invite user', err);
      return null;
    }
  }

  /**
   * Get invites for self
   */
  async getActiveInvites(): Promise<ActiveInviteResult[]> {
    try {
      const res = await ApiManager.get('/userinvites/active', {
        type: [TargetResource.SITE, TargetResource.PROMOTION],
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    } catch (err) {
      logger.debug(TAG, 'getAInvs: failed with:', err);
      return null;
    }
  }

  async resendInvite(inviteId: string) {
    try {
      const res = await ApiManager.post('/userinvites/' + inviteId, {
        action: 'resend',
      });
      return res.status === 200;
    } catch (err) {
      logger.error(TAG, 'resendInv: failed with:', err);
      return false;
    }
  }

  /**
   * Get pending invites for a site
   * @param siteId site ID
   * @param deviceId filter only invites for this device
   */
  async getPendingInvites(siteId: string, deviceId?: string)
    : Promise<PendingInviteResult[]> {
    try {
      const res = await ApiManager.get('/userinvites/pending', {
        type: TargetResource.SITE,
        id: siteId,
        device_id: deviceId ? deviceId : undefined,
      });
      return res.status === 200 ? res.data : null;
    } catch (err) {
      logger.error(TAG, 'getPndInv:failed with:', err);
      return null;
    }
  }

  async deleteInvite(inviteId: string) {
    try {
      const res = await ApiManager.delete('/userinvites/' + inviteId);
      return res.status === 200;
    } catch (err) {
      logger.error(TAG, 'deleteInv:failed with:', err);
      return false;
    }
  }

  async actOnInvite(inviteId: string, action: InviteAction,
    siteId?: string) {
    try {
      const res = await ApiManager.put('/userinvites/' + inviteId, {
        invite_status: action,
        site_id: siteId ? siteId : undefined,
      });
      return res.status === 200;
    } catch (err) {
      logger.error(TAG, 'actOInv:failed with:', err);
      return false;
    }
  }
}

const permissionsManager = new PermissionsManager();
export default permissionsManager;
