import SecureLs from 'secure-ls';
import logger from '../util/Logger';

const LOG_TAG = 'Kc';
const IOTREE_ECES = 'kNJE6Hp402eYnxUrASiSY3uNoHF7nsIw1BJB';
const secureLs = new SecureLs({
  isCompression: true,
  encodingType: 'aes',
  encryptionSecret: IOTREE_ECES,
  encryptionNamespace: 'iotree.domain',
});

class Keychain {
  async saveData(key: string, value: string) {
    try {
      secureLs.set(key, value);
    } catch (err) {
      logger.debug(LOG_TAG, 'failed to store key:' + key, err);
    }
  }

  async getData(key: string): Promise<string> {
    try {
      return secureLs.get(key);
    } catch (err) {
      logger.debug(LOG_TAG, 'failed to get key:' + key, err);
    }
    return null;
  }

  async clearData(key: string) {
    try {
      secureLs.remove(key);
    } catch (err) {
      logger.debug(LOG_TAG, 'failed to clear key:' + key, err);
    }
  }

  // removes all data in storage
  async deleteData() {
    try {
      secureLs.removeAll();
      secureLs.clear();
    } catch (err) {
      logger.debug(LOG_TAG, 'failed to delete data:', err);
    }
  }
}

const instance = new Keychain();
export default instance;
