import validator from 'validator';
import { format as DateFormat } from 'date-fns';
import logger from './Logger';

const TAG = 'Utils';
let thisEndian: string = null;

export default class Utils {
  static isNull(value: any): boolean {
    return typeof value === 'undefined' ||
      value === null;
  }

  static isEmptyString(value: any): boolean {
    return typeof value === 'string' && value.length === 0;
  }

  static isAlphaNumeric(str: string): boolean {
    let valid = false;
    if (str && typeof str === 'string') {
      valid = validator.isAlphanumeric(str);
    }
    return valid;
  }

  static isAlphaNumericWithSpaces(str: string): boolean {
    let valid = false;
    if (str && typeof str === 'string') {
      str = str.replace(/\s/g, '');
      valid = validator.isAlphanumeric(str);
    }
    return valid;
  }

  static isValidPostCode(pc: string, cc: string): boolean {
    let valid = false;
    if (pc && cc) {
      valid = validator.isPostalCode(pc, cc);
    }
    return valid;
  }

  static isValidEmail(str: string) {
    return str && validator.isEmail(str);
  }

  static isValidIpv4Address(str: string): boolean {
    return str && validator.isIP(str, '4');
  }

  static isValidUuid(str: string): boolean {
    return str && validator.isUUID(str, 4);
  }

  static formatDateTime(d: Date, is24Hour: boolean) {
    if (!is24Hour) {
      return DateFormat(d, 'dd/MM/yy h:mm:ss aaa');
    } else {
      return DateFormat(d, 'dd/MM/yy HH:mm:ss');
    }
  }

  static formatDay(d: Date) {
    return DateFormat(d, 'yy-MM-dd');
  }

  static formatTimeSecs(d: Date, is24Hour: boolean) {
    if (!is24Hour) {
      return DateFormat(d, 'h:mm:ss aaa');
    } else {
      return DateFormat(d, 'HH:mm:ss');
    }
  }

  static formatHour(d: Date, is24Hour: boolean) {
    if (!is24Hour) {
      return DateFormat(d, 'h aaa');
    } else {
      return DateFormat(d, 'HH:mm');
    }
  }

  static formatMonthRange(start: Date, end: Date) {
    if (start.getFullYear() !== end.getFullYear()) {
      return DateFormat(start, 'MMM yyyy') + ' - ' +
          DateFormat(end, 'MMM yyyy');
    } else if (start.getMonth() !== end.getMonth()) {
      return DateFormat(start, 'MMM') + ' - ' +
          DateFormat(end, 'MMM yyyy');
    } else {
      return DateFormat(end, 'MMM yyyy');
    }
  }

  static formatMonthDayYear(d: Date) {
    return DateFormat(d, 'MMM d yyy');
  }

  static formatMonthDay(d: Date) {
    return DateFormat(d, 'd MMM');
  }

  static formatLongMonthDayYear(d: Date) {
    return DateFormat(d, 'MMMM d yyy');
  }

  static formatDayForHtml(d: Date) {
    return DateFormat(d, 'yyyy-MM-dd');
  }

  static formatDayHour(hour: number) {
    if (hour < 12) {
      const num = hour === 0 ? 12 : hour;
      return num + ' AM';
    } else {
      let num = hour - 12;
      let suffix = ' PM';
      if (num === 0) {
        num = 12;
      } else if (num === 12) {
        suffix = ' AM';
      }
      return num + suffix;
    }
  }

  static formatDayHourRange(startHour: number, endHour: number) {
    if (startHour < 12 && endHour < 12) {
      if (startHour === 0) {
        startHour = 12;
      }
      return startHour + '-' + Utils.formatDayHour(endHour);
    } else if (startHour >= 12 && endHour > 12) {
      startHour = startHour - 12;
      if (startHour === 0) {
        startHour = 12;
      }
      return startHour + '-' + Utils.formatDayHour(endHour);
    } else {
      return Utils.formatDayHour(startHour) + '-' +
          Utils.formatDayHour(endHour);
    }
  }

  static formatTime(d: Date, is24Hour: boolean) {
    let hour = d.getHours();
    let minutes = d.getMinutes();
    let time: string;
    if (is24Hour) {
      time =
        `${Utils.formatLeadingZeros(hour, 2)}:${Utils.formatLeadingZeros(minutes, 2)}`;
    } else {
      let ampm: string;
      if (hour < 12) {
        ampm = 'AM';
        if (hour === 0) {
          hour = 12;
        }
      } else {
        ampm = 'PM';
        if (hour > 12) {
          hour = hour - 12;
        }
      }
      time =
        `${Utils.formatLeadingZeros(hour, 2)}:${Utils.formatLeadingZeros(minutes, 2)} ${ampm}`;
    }
    return time;
  }

  static formatLeadingZeros(num: number, zeroCount: number) {
    const prefix = zeroCount === 2 ? '00' : '000'; // only 2 or 3 supported
    let start = prefix + num;
    return start.substr(start.length - zeroCount);
  }

  static formatDuration(mins: number, secs: number) {
    return Utils.formatLeadingZeros(mins, 2) + ':' +
      Utils.formatLeadingZeros(secs, 2);
  }

  static formatMsDuration(millisecs: number) {
    const fullSecs = Math.round(millisecs / 1000);
    const mins = Math.floor(fullSecs / 60);
    const secs = fullSecs % 60;
    return Utils.formatDuration(mins, secs);
  }

  static async waitFor(millis: number): Promise<void> {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        resolve();
      }, millis);
    });
  }

  static async waitForCondition(millis: number,
    numTimes: number, condition: () => boolean): Promise<boolean> {
    while (!condition() && numTimes >= 0) {
      await Utils.waitFor(millis);
      numTimes--;
    }
    return condition();
  }

  static duplicate(obj: Object) {
    return obj ? JSON.parse(JSON.stringify(obj)) : null;
  }

  static endianness() {
    if (thisEndian) {
      return thisEndian;
    }
    const b = new ArrayBuffer(4);
    const a = new Uint32Array(b);
    const c = new Uint8Array(b);
    a[0] = 0xdeadbeef;
    if (c[0] === 0xef) {
      thisEndian = 'le';
      return 'le';
    }
    if (c[0] === 0xde) {
      thisEndian = 'be';
      return 'be';
    }
    throw new Error('unknown endianness');
  }

  static safeJsonParse(value: string): any {
    let json = null;
    if (value && typeof value === 'string') {
      try {
        json = JSON.parse(value);
      } catch (ignored) {}
    }
    return json;
  }

  static arePointsTooClose(p1, p2, diff) {
    const distance =
      Math.sqrt(Math.pow(p1.x - p2.x, 2) +
        Math.pow(p1.y - p2.y, 2));
    return distance < diff;
  }

  /**
   * From:
   * https://stackoverflow.com/a/45372025
   """Return True if the polygon defined by the sequence of 2D
    points is 'strictly convex': points are valid, side lengths non-
    zero, interior angles are strictly between zero and a straight
    angle, and the polygon does not intersect itself.

    NOTES:  1.  Algorithm: the signed changes of the direction angles
                from one side to the next side must be all positive or
                all negative, and their sum must equal plus-or-minus
                one full turn (2 pi radians). Also check for too few,
                invalid, or repeated points.
            2.  No check is explicitly done for zero internal angles
                (180 degree direction-change angle) as this is covered
                in other ways, including the `n < 3` check.
    """
   */
  static isConvexPolygon(points, minDiff) {
    if (!Array.isArray(points) || points.length < 3) {
      return false;
    }
    if (points.length === 3) {
      return true;
    }
    const TWO_PI = 2 * Math.PI;
    try {
      let oldPoint = points[points.length - 2];
      let newPoint = points[points.length - 1];
      let newDirection = Math.atan2(
        newPoint.y - oldPoint.y, newPoint.x - oldPoint.x);
      let angleSum = 0, orientation = 0;

      for (let i = 0; i < points.length; i++) {
        oldPoint = newPoint;
        let oldDirection = newDirection;
        newPoint = points[i];
        newDirection = Math.atan2(
          newPoint.y - oldPoint.y, newPoint.x - oldPoint.x);
        if (Utils.arePointsTooClose(oldPoint, newPoint, minDiff)) {
          return false;
        }
        let angle = newDirection - oldDirection;
        // convert angle to half-open interval (-pi, pi]
        if (angle <= -Math.PI) {
          angle += TWO_PI;
        } else if (angle > Math.PI) {
          angle -= TWO_PI;
        }
        if (i === 0) {
          if (angle === 0.0) {
            return false;
          }
          if (angle > 0.0) {
            orientation = 1.0;
          } else {
            orientation = -1.0;
          }
        } else {
          if (orientation * angle <= 0.0) {
            return false;
          }
        }
        angleSum += angle;
      }
      return Math.abs(Math.round(angleSum / TWO_PI)) === 1;
    } catch (error) {
      logger.error(TAG, 'failed to check polygon:', error);
      return false;
    }
  }
}
